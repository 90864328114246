import getFooterLinksGql from '~/graphql/queries/data/get-footer-links.gql'

export default async({ app, store }: any) => {
  const client = app.apolloProvider.defaultClient

  const response = await client.query({
    query: getFooterLinksGql,
    variables: {
      preview: store.state.settings.preview
    }
  })
  const footerLinks = response.data.edsFooterLinksCollection.items[0].linkItems

  store.dispatch('list/setFooterLinks', footerLinks)
}