import { Vue } from 'nuxt-property-decorator'
import { gql } from '@apollo/client/core'
import Product from '~/interfaces/product'
import getProductsGql from '~/graphql/queries/builder/get-products-gql'

export default Vue.extend({
  methods: {
    async checkProductQuantityMessaging(): Promise<void> {
      try {
        const families: Array<string> = await this.fetchProductFamilyList()
        const products: Array<Product> = await this.fetchProductsInFamily(families)
        this.processProducts(products)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`failed to get the products. error: ${err}`)
      }
    },
    updateProductQuantityMessaging(sapMaterialNumber: string): Promise<void> {
      return this.$getProductQuantity(sapMaterialNumber)
        .then((productQty: number | null) => {
          if (productQty !== null && !isNaN(productQty)) {
            this.updateProductMessaging(sapMaterialNumber, productQty)
          }
        })
    },
    fetchProductFamilyList(): Promise<Array<string>> {
      return this.$apollo.query({
        query: gql`query {
          edsEventsCollection {
            items {
              list
            }
          }
        }`
      })
        .then(({ data }) => data?.edsEventsCollection?.items[0]?.list)
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error('Failed to fetch product family list')
          return []
        })
    },
    fetchProductsInFamily(productFamilies: Array<string>): Promise<Array<Product>> {
      return this.$apollo.query({
        query: getProductsGql({ family_in: productFamilies }),
        variables: { preview: this.$store.state.settings.preview },
      })
        .then(({ data }) => data.edsProductCollection.items)
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error('Failed to fetch products')
          return []
        })
    },
    processProducts(products: Array<Product>): void {
      for (const product of products) {
        if (!product.limitedQuantity || !product.soldOut) {
          this.updateProductQuantityMessaging(product.sapMaterialNumber)
            .catch((err) => {
            // eslint-disable-next-line no-console
              console.error(`failed to updating product quantity messaging. error: ${err}`)
            })
        }
      }
    },
    updateProductMessaging(sapMaterialNumber: string, productQty: number) {
      if (productQty <= 0) {
        this.$setSoldOut(sapMaterialNumber, true)
      }
      if (productQty <= 10) {
        this.$setLimitedQuantity(sapMaterialNumber, true)
      }
    }
  },
})