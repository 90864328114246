
  import { Vue } from 'nuxt-property-decorator'

  export default Vue.extend({
    data() {
      return {
        basketCount: null as number | null,
        altText: 'basket is empty',
        item: ''
      }
    },
    computed: {
      basket(): number {
        let count = 0
        for (const item of this.$store.state.list.basket) {
          count += item.quantity
        }

        return count
      },
      showGoToBasketLoader() : null | string {
        return this.$store.state.showGoToBasketLoader
      }
    },
    watch: {
      basket(newCount: number) {
        this.basketCount = newCount
        this.item = newCount > 1 ? 'items' : 'item'
      }
    },
    methods: {
      async goToBasket() {
        try {
          this.$store.dispatch('setShowGoToBasketLoader', 'header')
          await this.$basketCheckout()
          this.$store.dispatch('setShowGoToBasketLoader', null)
        } catch (error) {
          this.$store.dispatch('setShowGoToBasketLoader', null)
        }
      }
    }
  })
