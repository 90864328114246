export default {
  methods: {
    fromKebabToCamelCase(text: string): string {
      const formattedText = text.split('-').map(function(word: string) {
        return word[0].toUpperCase() + word.substring(1)
      }).join('')
      return formattedText[0].toLowerCase() + formattedText.substring(1)
    },
    fromKebabToTitleCase(text: string): string {
      return text?.split('-').map(function(word) {
        return word[0].toUpperCase() + word.substring(1)
      }).join(' ')
    },
    toCamelCase(text: string): string {
      return text?.replace(/(?:^\w|\[A-Z\]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase()
      }).replace(/\s+/g, '')
    },
    toKebabCase(text: string): string {
      return text?.replace(/\s+/g, '-').toLowerCase()
    },
    formatTitleCase(str: string): string {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
        }
      )
    },
    /**
     * Converts a kebab-case string to snake_case.
     * @param text - The kebab-case string to convert.
     * @returns The snake_case string.
     */
    fromKebabToSnakeCase(text: string): string {
      return text?.replace(/-/g, '_')
    },
    /**
     * Helper function to check if a string contains a diacritic character
     * @param _text - The string to check for.
     * @returns Boolean.
     */
    containsDiacriticChar(_text: string): boolean {
      const text = typeof _text === 'string' ? _text : `${_text}`
      return /[À-ÖØ-öø-ÿ]/.test(text)
    },
    /**
     * Converts diacritic characters in a string to a plain character
     * @param _text - The string to normalize
     * @returns Normalized string.
     */
    normalizeDiacritic(_text: string): string {
      if (!_text) { return '' }
      const text = typeof _text === 'string' ? _text : `${_text}`
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
  }
}