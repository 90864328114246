var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"language-search-container"},[_c('label',{staticClass:"language-input-label eds-label-regular",attrs:{"for":"language-search-input-ele"}},[_vm._v("\n    "+_vm._s(_vm.$t('Language'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"mt-1"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
        fn: () => {
          _vm.showDropdown = false
          _vm.showDropdownOnTab = _vm.showDropdown ? _vm.showDropdownOnTab : false
        }
      }),expression:"{\n        fn: () => {\n          showDropdown = false\n          showDropdownOnTab = showDropdown ? showDropdownOnTab : false\n        }\n      }"}],staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedLanguageModel),expression:"selectedLanguageModel"}],ref:"languageSearchInputRef",staticClass:"language-search-input",class:{
          'focus': _vm.showDropdown || _vm.showDropdownOnTab
        },attrs:{"id":"language-search-input-ele","autocomplete":"off","spellcheck":"false","tabindex":"0"},domProps:{"value":(_vm.selectedLanguageModel)},on:{"keydown":function($event){return _vm.onInputKeyDown($event)},"focus":function($event){_vm.showDropdown = true},"input":[function($event){if($event.target.composing)return;_vm.selectedLanguageModel=$event.target.value},function($event){return _vm.onInputChange()}]}}),_vm._v(" "),_c('button',{staticClass:"input-caret",attrs:{"tabindex":"-1"},on:{"click":_vm.toggleDropdown}},[_c('img',{attrs:{"src":require("assets/icons/caret.svg"),"alt":"input-caret","width":"12","height":"6"}})]),_vm._v(" "),_c('div',{staticClass:"input-bottom-bar"})]),_vm._v(" "),_c('div',{staticClass:"languages-dropdown",class:{ 'active': _vm.showDropdown || _vm.showDropdownOnTab }},[_c('ul',{ref:"languagesListRef"},[(!_vm.filteredLanguages.length)?_c('li',{attrs:{"tabindex":"0"},on:{"keydown":function($event){return _vm.onListKeyDown($event, { languageIndex: 0, filteredLanguages: [] })}}},[_vm._v("\n          "+_vm._s(_vm.$t('No Result'))+"\n        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.filteredLanguages),function(languageData,languageIndex){return _c('li',{key:languageData.language + '-' + languageIndex,attrs:{"tabindex":"0"},on:{"mousedown":function($event){return _vm.setLanguage($event, languageData)},"keydown":function($event){return _vm.onListKeyDown($event, { languageIndex: languageIndex, filteredLanguages: _vm.filteredLanguages })}}},[_vm._v("\n          "+_vm._s(`${languageData.language} (${languageData.locale})`)+"\n        ")])})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }