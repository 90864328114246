import { Vue } from 'nuxt-property-decorator'

export default Vue.extend({
  data(): {
    _timer: ReturnType<typeof setTimeout> | undefined,
    _lastPromiseResolve: Function | undefined,
  } {
    return {
      _timer: undefined,
      _lastPromiseResolve: undefined
    }
  },
  methods: {
    debounce(ms: number = 350): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        this._timer = setTimeout(() => {
          clearTimeout(this._timer)
          resolve()
        }, ms)
      })
    },
    debounceWrapper(ms: number, fn: Function): Promise<void> {
      if (this._timer && this._lastPromiseResolve) {
        // Cancels succeeding calls, and will resolve the last called request. 
        clearTimeout(this._timer)
        this._lastPromiseResolve()
      }

      return new Promise<void>((resolve, reject) => {
        this._lastPromiseResolve = resolve
        this._timer = setTimeout(() => {
          clearTimeout(this._timer)
          resolve(fn())
        }, ms)
      })
    }
  }
})