
  import { Vue } from 'nuxt-property-decorator'
  import HeaderMenu from '~/interfaces/header-menu'

  export default Vue.extend({
    props: {
      isMobileNavOpen: {
        type: Boolean,
        default() { return false }
      },
      menu: {
        type: Array as () => Array<HeaderMenu>,
        default() { return [] }
      }
    },
    data() {
      return {
        isInSearchResultPage: this.$route.path === '/search',
      }
    },
    watch: {
      $route() {
        this.isInSearchResultPage = this.$route.path === '/search'
      },
      isMobileNavOpen(newValue) {
        if (newValue && this.menu.findIndex(item => item.active) < 0) {
          this.menu.forEach((item: HeaderMenu, index: number) => {
            if (index === 0) {
              item.active = true
            }
          })
        }
      }
    }
  })
