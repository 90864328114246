import { getBasketItems, validateTempCustomerId } from './basket-common-service'
import BasketProps from '~/interfaces/basket-props'

export default async function(axios: any, basketProps: BasketProps, $store: any) {
  try {
    $store.dispatch('setIsBasketSynced', false)
    const headers = {
      common: {
        'x-api-key': basketProps.apiKey
      }
    }
    const loginToken = $store.state.user?.token || null
    const loginUser = $store.state.user?.user || null
    if (loginToken && loginUser) {
      axios.setToken(loginToken, 'Bearer')
    }
    const customerId: string = await validateTempCustomerId(
      axios, basketProps, headers, $store.state.customer.customerId
    )
    $store.dispatch('customer/setCustomerId', customerId)
    $store.dispatch('list/emptyBasket')

    const storeId = basketProps.storeId
    const basket = await getBasketItems(axios, basketProps, headers, customerId, storeId) as unknown as any[]
    if (basket.length > 0) {
      basket.forEach((item: any) => {
        $store.dispatch('list/addToBasket', {
          sapMaterialNumber: item.product.sku,
          quantity: item.quantity
        })
      })
    }
    $store.dispatch('setIsBasketSynced', true)
  } catch (response: any) {
    // eslint-disable-next-line no-console
    console.log(response.message, 'There was an error in the GET basket items API call.')
  }
}