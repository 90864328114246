import Language from '~/interfaces/language'

export default ({ store }: any) => {
  const languages: Language[] = store.state.list.languages
  const countryCode = store.state.country.countryCodeIso2
  const urlSearchParams = new URLSearchParams(window.location.search)
  let languageLocale = ''
  const sitelang = urlSearchParams.get('sitelang')
  const isLangSupported: Language | undefined =
    languages.find(language => language.locale.toLowerCase() === sitelang?.toLowerCase())
  /**
   * Detects the language based on the URL parameters and sets the locale in the store.
   */
  if (sitelang && isLangSupported) {
    languageLocale = isLangSupported.regionalLocale
  } else {
    languageLocale = store.state.localisation.locale ||
      languages?.find((entry: Language) => entry.locale === countryCode)?.regionalLocale ||
      languages?.find((entry: Language) => entry.locale === 'US')?.regionalLocale
  }
  store.dispatch('localisation/setLocale', languageLocale)
}