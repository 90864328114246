import { fetchTempCustomerId, updateBasketItems } from './basket-common-service'
import basketSyncService from '~/services/basket-sync-service'
import BasketProps from '~/interfaces/basket-props'

export default async function(axios: any, basketProps: BasketProps, $store: any) {
  try {
    const anonymousBasketItems = [...$store.state.list.basket]
    const headers = {
      common: {
        'x-api-key': basketProps.apiKey
      }
    }
    const loginToken = $store.state.user?.token || null
    const loginUser = $store.state.user?.user || null
    if (loginToken && loginUser) {
      axios.setToken(loginToken, 'Bearer')
    }
    const storeId = basketProps.storeId
    const customerId: string | number = await fetchTempCustomerId(axios, basketProps, headers)
    $store.dispatch('customer/setCustomerId', customerId)

    for (const basketItem of anonymousBasketItems) {
      await updateBasketItems(
        axios, basketProps, headers, customerId, storeId, basketItem.sapMaterialNumber
      )
    }
  } catch (response: any) {
    // eslint-disable-next-line no-console
    console.log(response.message, 'There was an error in merging non-signed-in and signed-in basket items.')
  } finally {
    await basketSyncService(axios, basketProps, $store)
  }
}