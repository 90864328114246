
  import { Vue } from 'nuxt-property-decorator'

  export default Vue.extend({
    computed: {
      skipLinkBanner() {
        return document.querySelector('.skip-links')
      }
    },
    watch: {
      $route(newVal) {
        if (newVal && !this.$route.query.filters) {
          let count = 0
          const skipLink = document.querySelectorAll('.skip-to-main-content')[0]

          document.addEventListener('keyup', (event) => {
            if (event.key === 'Tab' && count === 0) {
              this.skipLinkBanner.classList.add('open')
              skipLink.focus()
              ++count
            }
          })
        }
      }
    },
    mounted() {
      this.bindSkipLinkEvents()
    },
    methods: {
      bindSkipLinkEvents() {
        const skipLink = document.querySelectorAll('.skip-to-main-content')[0]

        document.addEventListener('keyup', (event) => {
          if (event.key === 'Tab' && !!document.activeElement) {
            let focusedElement = document.activeElement

            if (focusedElement && focusedElement.tagName === 'html') {
              focusedElement = skipLink
            }

            const focusedElementParent = focusedElement.parentElement

            if (focusedElement && focusedElementParent.classList.contains('skip-links')) {
              this.skipLinkBanner.classList.add('open')
            } else {
              this.skipLinkBanner.classList.remove('open')
            }
          }
        })

        skipLink.addEventListener('blur', () => {
          const focusedElement = document.activeElement

          if (!focusedElement.classList.contains('skip-links')) {
            this.skipLinkBanner.classList.remove('open')
          }
        })
      },
      skipToMainContent(e) {
        if (e.key === 'Enter') {
          const mainContent = document.querySelector('.main-content')
          const focusableElements = mainContent.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
          )
          const isThereFocusableElements = focusableElements.length !== 0

          if (isThereFocusableElements) {
            focusableElements[0].focus()
          } else {
            mainContent.focus()
          }

          this.skipLinkBanner.classList.remove('open')
        }
      },
    }
  })
