export default {
  methods: {
    formatToHttps(url: string): string {
      const isNotHttps = url.slice(0, 8) !== 'https://'
      return isNotHttps ? url.replace('http://', 'https://') : url
    },
    parseLink(link: string | null) {
      if (link && link.includes('path://')) {
        return link.replace('path:/', '')
      }
      return false
    }
  },
}