import getLocalisedContent from '~/graphql/queries/data/get-localised-content.gql'
import LocalisedContent from '~/interfaces/localisedContent'

export default async({ app, store }: any) => {
  const client = app.apolloProvider.defaultClient

  const localisedContentResponse = await client.query({
    query: getLocalisedContent,
    variables: {
      locale: store.state.localisation.locale,
      preview: store.state.settings.preview
    }
  })
  const localisedContent: LocalisedContent[] = localisedContentResponse?.data?.edsLocalisedContentCollection?.items
  const content: any = {}
  for (const item of localisedContent) {
    content[item.enValue] = item.localisation
  }
  store.dispatch('localisation/setContent', content)
}