import { render, staticRenderFns } from "./HeaderBasket.vue?vue&type=template&id=c46562ee&scoped=true&"
import script from "./HeaderBasket.vue?vue&type=script&lang=ts&"
export * from "./HeaderBasket.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderBasket.vue?vue&type=style&index=0&id=c46562ee&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c46562ee",
  null
  
)

export default component.exports