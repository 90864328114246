export default {
  "orders": [
    {
      "date_purchased": "2023-01-21",
      "orders_id": "123456",
      "storeId": "store_wli",
      "status": 11,
      "statusText": "Order Accepted",
      "currencyCode": "GBP",
      "orderProducts": [
        {
          "sku": "2800464539",
          "name": "IELTS Academic Mini Writing Task 1 Understanding the Examiner Comments",
          "price": "2.99",
          "price0": "",
          "price1": "",
          "quantity": "1",
          "refundValue": "0.00",
          "customAttrArray": []
        },
        {
          "sku": "2800464540",
          "name": "IELTS Academic Mini Writing Task 2 Understanding the Examiner Comments",
          "price": "2.99",
          "price0": "",
          "price1": "",
          "quantity": "1",
          "refundValue": "0.00",
          "customAttrArray": []
        }
      ],
      "paymentMethod": "GlobalGreenzone"
    },
    {
      "date_purchased": "2023-02-22",
      "orders_id": "1234567",
      "storeId": "store_wli",
      "status": 11,
      "statusText": "Order Accepted",
      "currencyCode": "AUD",
      "orderProducts": [
        {
            "sku": "2800485810",
            "name": "IELTS Band Buster Academic Writing Task 1 for Band 5+",
            "price": "6.95",
            "price0": "",
            "price1": "",
            "quantity": "1",
            "refundValue": "0.00",
            "customAttrArray": []
        }
      ],
      "paymentMethod": "GlobalGreenzone"
    },
    {
      "date_purchased": "2023-03-23",
      "orders_id": "12345678",
      "storeId": "store_wli",
      "status": 11,
      "statusText": "Order Accepted",
      "currencyCode": "NZD",
      "orderProducts": [
        {
            "sku": "2800485451",
            "name": "Evolve Digital Level 6 Student’s Course",
            "price": "66.95",
            "price0": "",
            "price1": "",
            "quantity": "1",
            "refundValue": "0.00",
            "customAttrArray": []
        }
      ],
      "paymentMethod": "GlobalGreenzone"
    },
    {
      "date_purchased": "2023-03-23",
      "orders_id": "123456789",
      "storeId": "store_wli",
      "status": 11,
      "statusText": "Order Accepted",
      "currencyCode": "NZD",
      "orderProducts": [
        {
            "sku": "2800470771",
            "name": "Top Tips for IELTS Academic Reading",
            "price": "4.95",
            "price0": "",
            "price1": "",
            "quantity": "1",
            "refundValue": "0.00",
            "customAttrArray": []
        },
        {
            "sku": "2800485824",
            "name": "IELTS  Booster Academic Self-Study Edition",
            "price": "20.95",
            "price0": "",
            "price1": "",
            "quantity": "1",
            "refundValue": "0.00",
            "customAttrArray": []
        }
      ],
      "paymentMethod": "GlobalGreenzone"
    },
    {
      "date_purchased": "2023-02-22",
      "orders_id": "1234567",
      "storeId": "store_wli",
      "status": 11,
      "statusText": "Order Accepted",
      "currencyCode": "AUD",
      "orderProducts": [
        {
            "sku": "2800485810",
            "name": "IELTS Band Buster Academic Writing Task 1 for Band 5+",
            "price": "6.95",
            "price0": "",
            "price1": "",
            "quantity": "1",
            "refundValue": "0.00",
            "customAttrArray": []
        }
      ],
      "paymentMethod": "GlobalGreenzone"
    },
    {
      "date_purchased": "2023-03-23",
      "orders_id": "12345678",
      "storeId": "store_wli",
      "status": 11,
      "statusText": "Order Accepted",
      "currencyCode": "NZD",
      "orderProducts": [
        {
            "sku": "2800485451",
            "name": "Evolve Digital Level 6 Student’s Course",
            "price": "66.95",
            "price0": "",
            "price1": "",
            "quantity": "1",
            "refundValue": "0.00",
            "customAttrArray": []
        }
      ],
      "paymentMethod": "GlobalGreenzone"
    },
    {
      "date_purchased": "2023-03-23",
      "orders_id": "123456789",
      "storeId": "store_wli",
      "status": 11,
      "statusText": "Order Accepted",
      "currencyCode": "NZD",
      "orderProducts": [
        {
            "sku": "2800470771",
            "name": "Top Tips for IELTS Academic Reading",
            "price": "4.95",
            "price0": "",
            "price1": "",
            "quantity": "1",
            "refundValue": "0.00",
            "customAttrArray": []
        },
        {
            "sku": "2800485824",
            "name": "IELTS  Booster Academic Self-Study Edition",
            "price": "20.95",
            "price0": "",
            "price1": "",
            "quantity": "1",
            "refundValue": "0.00",
            "customAttrArray": []
        }
      ],
      "paymentMethod": "GlobalGreenzone"
    }
  ]
}