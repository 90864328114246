import { TranslateDataKeyOptions } from '~/interfaces/translate-data-key-options'

const LabelAndTextDataKeys = { 
  completeYourProfile: 'HEADER_67947632559311976_LABEL',
  emailAddress: 'TEXTBOX_136884197726350880_LABEL',
  firstName: 'TEXTBOX_65559603100946710_LABEL',
  lastName: 'TEXTBOX_115270360415748670_LABEL',
  yourCountry: 'LABEL_130277852614992380_LABEL',
  byContinuing: 'LABEL_28854870059218510_LABEL',
  termsAndConditions: 'LINK_56179583666790620_LABEL',
  and: 'LABEL_101783884719220270_LABEL',
  privacyNotice: 'LINK_15090096674456668_LABEL',
}

const LabelAndTextOptions: TranslateDataKeyOptions[] = Object.values(LabelAndTextDataKeys).map(dataKey => ({
  key: ` [data-translation-key="${dataKey}"]`,
  root: '.gigya-profile-form',
}))

const submitButton: TranslateDataKeyOptions = {
  key: '#gigya-profile-form > div:nth-child(2) > div > input',
  attr: 'value',
  root: '.gigya-profile-form',
}

export const completeYourProfileTranslations: TranslateDataKeyOptions[] = [
  ...LabelAndTextOptions,
  submitButton
]
