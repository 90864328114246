
  import { Vue } from 'nuxt-property-decorator'
  import Language from '~/interfaces/language'
  import mixinFormatText from '~/mixins/formatText'
  import mixinDebounce from '~/mixins/debounce'

  export default Vue.extend({
    mixins: [mixinFormatText, mixinDebounce],
    data():{
      languages: Language[],
      filteredLanguages: Language[],
      selectedLanguageModel: string,
      selectedLanguageData: Language | null,
      showDropdown: boolean,
      showDropdownOnTab: boolean
    } {
      return {
        languages: this.$store.state.list.languages,
        filteredLanguages: this.$store.state.list.languages,
        selectedLanguageModel: '',
        selectedLanguageData: null,
        showDropdown: false,
        showDropdownOnTab: false
      }
    },
    watch: {
      selectedLanguageModel: {
        immediate: true,
        handler() {
          this.emitSelectedLanguage()
        }
      }
    },
    beforeMount() {
      this.selectedLanguageData = this.getSelectedLanguageData()
      this.selectedLanguageModel = `${this.selectedLanguageData.language} (${this.selectedLanguageData.locale})`
    },
    methods: {
      toggleDropdown() {
        this.showDropdown = !this.showDropdown
        const languageSearchInput = this.$refs.languageSearchInputRef as HTMLInputElement
        if (this.showDropdown) {
          languageSearchInput.focus()
        } else {
          this.showDropdownOnTab = false
          languageSearchInput.blur()
        }
      },
      getSelectedLanguageData(): Language {
        const storedLocale = this.$store.state.localisation.locale
        return (this.languages.find((language: Language) => language.regionalLocale === storedLocale) ||
          this.languages.find(
            (language: Language) => language.regionalLocale === this.$store.state.localisation.defaultLocale) ||
          this.languages[0])
      },
      clearSelectedLanguageData() {
        this.selectedLanguageData = {
          country: '',
          language: '',
          locale: '',
          regionalLocale: '',
        }
      },
      setLanguage(event: any, selectedLanguage: Language): void {
        this.selectedLanguageData = selectedLanguage
        this.selectedLanguageModel = `${this.selectedLanguageData.language} (${this.selectedLanguageData.locale})`
        this.showDropdown = false
        this.showDropdownOnTab = false
        this.emitSelectedLanguage()
        event.stopPropagation()
      },
      emitSelectedLanguage(): void {
        this.$emit('selectedLanguage', this.selectedLanguageData)
      },
      async onInputChange() {
        await this.debounceWrapper(300, () => {
          const keyword = this.selectedLanguageModel.toLowerCase().trim()
          if (!this.showDropdown && keyword.length > 0) {
            this.showDropdown = true
          }
          this.filteredLanguages = this.languages?.filter((obj: Language) => {
            const languageOptions = [
              `${obj.language.toLowerCase()} (${obj.locale.toLowerCase()})`,
              `${obj.language.toLowerCase()} ${obj.locale.toLowerCase()}`
            ]

            return languageOptions.find((option: string) => {
              if (!keyword) { return true }
              if (this.containsDiacriticChar(keyword)) {
                return option.includes(keyword)
              }
              return this.normalizeDiacritic(option).includes(keyword)
            })
          })
        })
      },
      onInputKeyDown(event: any): void {
        this.showDropdownOnTab = (event.keyCode === 9 && !event.shiftKey)

        if (event.keyCode === 40) {
          event.preventDefault()
          const dropdownList = this.getDropdownListElements()
          if (dropdownList.length > 0) {
            this.showDropdownOnTab = true
            dropdownList[0].focus()
          }
        } else if (event.keyCode === 27) {
          const languageSearchInput = this.$refs.languageSearchInputRef as any
          languageSearchInput.blur()
          this.showDropdown = false
        }
        this.clearSelectedLanguageData()
      },
      onListKeyDown(event: any, option: any = {}): void {
        const {
          languageIndex, filteredLanguages
        } = option
        const dropdownList = this.getDropdownListElements()

        if (event.keyCode === 13 && filteredLanguages.length < 1) {
          this.showDropdownOnTab = false
        } else if (event.keyCode === 13) {
          const selectedLanguage = filteredLanguages[languageIndex]
          this.setLanguage(event, selectedLanguage)
        } else if (event.keyCode === 9 && !event.shiftKey && (
          (languageIndex + 1) === filteredLanguages.length || filteredLanguages.length < 1
        )) {
          this.showDropdownOnTab = false
        } else if (event.keyCode === 38) {
          event.preventDefault()
          if (languageIndex < 1) {
            const languageSearchInput = this.$refs.languageSearchInputRef as any
            languageSearchInput.focus()
          } else {
            dropdownList[languageIndex - 1].focus()
          }
        } else if (event.keyCode === 40) {
          event.preventDefault()
          if (languageIndex < (filteredLanguages.length - 1)) {
            dropdownList[languageIndex + 1].focus()
          }
        } else if (event.keyCode === 27) {
          this.showDropdownOnTab = false
          this.showDropdown = false
        }
      },
      getDropdownListElements() {
        const languagesListEl = this.$refs.languagesListRef as any
        return languagesListEl.querySelectorAll('li')
      }
    }
  })
