// @ts-ignore
import Context from '~/interfaces/context.ts'

export default ({
  $config, app, store
}: Context) => {
  if ($config.gigyaEnabled) {
    window.addEventListener('load', () => {
      // @ts-ignore
      const Gigya = gigya
      Gigya.accounts.session.verify({
        callback: (response: any) => {
          const { status } = response
          if (status === 'OK') {
            Gigya.accounts.getAccountInfo({
              // eslint-disable-next-line max-len
              include: 'identities-active, identities-all, identities-global, loginIDs, emails, profile, data, memberships, regSource, isLockedOut, lastLoginLocation, rba, subscriptions, userInfo, preferences, groups, id_token',
              callback: async(event: any) => {
                const { userInfo } = event
                const UID = userInfo?.UID
                await store.dispatch('user/setUserData', userInfo)
                await Gigya.accounts.getJWT({
                  fields: 'firstName, lastName, email',
                  UID,
                  expiration: 86400,
                  callback: async(response: any) => {
                    const token = response.id_token
                    if (token) {
                      await store.dispatch('user/setUserToken', token)
                      await app.$basketSync()
                    }
                  }
                })
              }
            })
          }
        }
      })
    })
  }
}