export const state = () => ({
  showPopupAddedToBasket: false,
  showAddToBasketErrorPopup: false,
  showBasketStickyBtn: false,
  isAddingToBasket: false,
  addedToBasketItem: null,
  showGoToBasketLoader: null,
  isCountryChange: null,
  returnURL: null,
  isPopupOpen: false,
  sapForAddToBasketError: null,
  isRetryAddingToBasket: false,
  isBasketSynched: null
});

export const mutations = {
  SET_SHOW_POPUP_ADDED_TO_BASKET(state, value) {
    state.showPopupAddedToBasket = value;
  },
  SET_SHOW_ADD_TO_BASKET_ERROR_POPUP(state, value) {
    state.showAddToBasketErrorPopup = value;
  },
  SET_SHOW_BASKET_STICKY_BTN(state, value) {
    state.showBasketStickyBtn = value;
  },
  SET_IS_ADDING_TO_BASKET(state, value) {
    state.isAddingToBasket = value;
  },
  SET_ADDED_TO_BASKET_ITEM(state, value) {
    state.addedToBasketItem = value;
  },
  SET_SHOW_GO_TO_BASKET_LOADER(state, value) {
    state.showGoToBasketLoader = value;
  },
  SET_IS_COUNTRY_CHANGE(state, value) {
    state.isCountryChange = value;
  },
  SET_RETURN_URL(state, value) {
    state.returnURL = value;
  },
  SET_IS_POPUP_OPEN(state, value) {
    state.isPopupOpen = value;
  },
  SET_SAP_FOR_ADD_TO_BASKET_ERROR(state, value) {
    state.sapForAddToBasketError = value;
  },
  SET_IS_RETRY_ADDING_TO_BASKET(state, value) {
    state.isRetryAddingToBasket = value;
  },
  SET_IS_BASKET_SYNCED(state, value) {
    state.isBasketSynched = value;
  }
};

export const actions = {
  setShowPopupAddedToBasket({ commit }, value) {
    commit('SET_SHOW_POPUP_ADDED_TO_BASKET', value);
  },
  setShowAddToBasketErrorPopup({ commit }, value) {
    commit('SET_SHOW_ADD_TO_BASKET_ERROR_POPUP', value);
  },
  setShowBasketStickyBtn({ commit }, value) {
    commit('SET_SHOW_BASKET_STICKY_BTN', value);
  },
  setIsAddingToBasket({ commit }, value) {
    commit('SET_IS_ADDING_TO_BASKET', value);
  },
  setAddedToBasketItem({ commit }, value) {
    commit('SET_ADDED_TO_BASKET_ITEM', value);
  },
  setShowGoToBasketLoader({ commit }, value) {
    commit('SET_SHOW_GO_TO_BASKET_LOADER', value);
  },
  setIsCountryChange({ commit }, value) {
    commit('SET_IS_COUNTRY_CHANGE', value);
  },
  setReturnURL({ commit }, value) {
    commit('SET_RETURN_URL', value);
  },
  setIsPopupOpen({ commit }, value) {
    commit('SET_IS_POPUP_OPEN', value);
  },
  setSapForAddToBasketError({ commit }, value) {
    commit('SET_SAP_FOR_ADD_TO_BASKET_ERROR', value);
  },
  setIsRetryAddingToBasket({ commit }, value) {
    commit('SET_IS_RETRY_ADDING_TO_BASKET', value);
  },
  setIsBasketSynced({ commit }, value) {
    commit('SET_IS_BASKET_SYNCED', value);
  }
};