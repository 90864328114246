
  import { Vue } from 'nuxt-property-decorator'
  import { sanitizeUrl } from '@braintree/sanitize-url'
  import HeaderMenu, { HeaderSubMenu } from '~/interfaces/header-menu'
  import mixinHandleLinkClick from '~/mixins/handleLinkClick'

  export default Vue.extend({
    mixins: [mixinHandleLinkClick],
    props: {
      menu: {
        type: Array as () => Array<HeaderMenu>,
        default() { return [] }
      },
      isInMobileOrTablet: {
        type: Boolean,
        default() { return false }
      },
      calculatedTopValue: {
        type: String,
        default() { return '0px' }
      }
    },
    computed: {
      enableBanner(): boolean {
        return this.$store.state.settings.maintenance.enabled
      }
    },
    methods: {
      sanitizeUrl,
      toggleMenu(index: number): void {
        let menuFlag = false
        this.menu.forEach((menu: HeaderMenu, menuIndex: number) => {
          menu.active = index === menuIndex && !menu.active
          if (menu.active) {
            menuFlag = true
          }
        })

        this.$emit('toggleMenu', menuFlag)
      },
      closeMenu(): void {
        if (!this.isInMobileOrTablet) {
          this.menu.forEach((menu: HeaderMenu) => {
            menu.active = false
          })

          this.$emit('toggleMenu', false)
        }
      },
      getGridRowsLength(subMenu: HeaderSubMenu): number {
        let count = 0

        if (subMenu.groupName) {
          count++
        }

        count += subMenu.navigations.length

        return (count > 5) ? 6 : count
      }
    }
  })
