import getFilters from '~/graphql/queries/data/get-filters.gql'

export default async({ app, store }: any) => {
  const client = app.apolloProvider.defaultClient

  const filtersResponse = await client.query({
    query: getFilters,
    variables: {
      preview: store.state.settings.preview
    }
  })
  const filters = filtersResponse.data.edsFiltersCollection.items

  store.dispatch('list/setFilters', filters)
}