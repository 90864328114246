/* eslint-disable max-len */
export const screensetCustomLang: Record<string, string> = {
  'SUBMIT_142674579108303380_VALUE': 'Continue',
  'HEADER_153756110421239460_LABEL': '<a href="/english/register">Register</a>',
  'HEADER_57709528794549710_LABEL': '<a href="/english/signin">Sign in</a>',
  'LABEL_139998946623832940_LABEL': 'Password',
  'LABEL_48902362044111190_LABEL': 'Or',
  'LABEL_66482287771246690_LABEL': 'Email address',
  'LINK_146308315993881860_LABEL': 'Forgot password?',
  'GIGYA_LOGIN_SCREEN_CAPTION': 'Login',
  'SUBMIT_7667737432077126_VALUE': 'Register now',
  'HEADER_108728337563591200_LABEL': '<a href="/english/register">Register</a>',
  'HEADER_63695688742051540_LABEL': '<a href="/english/signin">Sign in</a>',
  'LABEL_103722341444128200_LABEL': '&nbsp;&nbsp;and',
  'LABEL_12306136368372000_LABEL': 'Email address',
  'LABEL_23360349088437170_LABEL': 'Get access to Cambridge products and services by creating an account',
  'LABEL_26425230947552150_LABEL': 'Your country',
  'LABEL_31875759361993188_LABEL': 'By continuing, you’re agreeing with the',
  'LABEL_43059823842119176_LABEL': 'Password',
  'LABEL_44483220097029120_LABEL': 'Last name',
  'LABEL_46301171957907990_LABEL': 'First name',
  'LINK_114035836220497150_LABEL': 'privacy notice',
  'LINK_55817364832410216_LABEL': 'Back to register options',
  'LINK_75673091727259140_LABEL': 'terms &amp; conditions',
  'GIGYA_REGISTER_SCREEN_CAPTION': 'Registration',
  'SUBMIT_31429658457676556_VALUE': 'Submit',
  'HEADER_67947632559311976_LABEL': 'Complete your profile',
  'LABEL_101783884719220270_LABEL': '&nbsp;&nbsp;and',
  'LABEL_130277852614992380_LABEL': 'Your country',
  'LABEL_28854870059218510_LABEL': 'By continuing, you are agreeing to the',
  'LINK_15090096674456668_LABEL': 'privacy notice',
  'LINK_56179583666790620_LABEL': 'terms &amp; conditions',
  'GIGYA_COMPLETE_REGISTRATION_SCREEN_CAPTION': 'Profile Completion',
  'TEXTBOX_115270360415748670_LABEL': 'Last name',
  'TEXTBOX_136884197726350880_LABEL': 'Email address',
  'TEXTBOX_65559603100946710_LABEL': 'First name',
  'SUBMIT_314293454325435_VALUE': 'Get The Code',
  'LABEL_1555592368664_LABEL': 'A code will be sent to your mobile phone number.',
  'GIGYA_MOBILE_LOGIN_SCREEN_CAPTION': 'Log In With Your Mobile Phone',
  'SUBMIT_31429890890890_VALUE': 'Verify',
  'LABEL_1555593146287_LABEL': 'Enter the code sent to your phone <a data-binding="true" data-switch-screen="gigya-mobile-login-screen"> {{accountInfo.phoneNumber}}\n <i class="gigya-pencil"></i></a>',
  'LABEL_546546790858_LABEL': 'Didn\'t get the code? <a data-disable-on-render="true" data-gigya-api-link="accounts.otp.sendCode">Click Here</a>',
  'GIGYA_MOBILE_VERIFICATION_SCREEN_CAPTION': 'Enter The Code',
  'SUBMIT_137794634987176350_VALUE': 'Get The Code',
  'LABEL_56426764264083580_LABEL': 'A code will be sent to the new email for\n verification. If you don\'t validate with the code, your email will remain unchanged.',
  'GIGYA_CHANGE_EMAIL_SCREEN_CAPTION': 'Change Your Email',
  'TEXTBOX_20913098960397852_LABEL': 'Email',
  'SUBMIT_106757411555670860_VALUE': 'Verify',
  'LABEL_124580104205672500_LABEL': 'Enter the code sent to your email <a data-binding="true" data-switch-screen="gigya-change-email-screen"> {{accountInfo.email}}\n <i class="gigya-pencil"></i></a>',
  'LABEL_45421446589093550_LABEL': 'Didn\'t get the code? <a data-disable-on-render="true" data-gigya-api-link="accounts.otp.sendCode">Click Here</a>',
  'GIGYA_EMAIL_CODE_VERIFICATION_SCREEN_CAPTION': 'Enter The Code',
  'SUBMIT_81725475708397440_VALUE': 'Reset password',
  'HEADER_101499678640920860_LABEL': 'Reset your password',
  'LABEL_19299858817415948_LABEL': 'Email address',
  'LABEL_53453282263825500_LABEL': 'Please enter the email address linked to your account and we’ll send a link to reset your password',
  'LINK_125263109889403890_LABEL': 'Back to sign in',
  'GIGYA_FORGOT_PASSWORD_SCREEN_CAPTION': 'Forgot Password',
  'HEADER_166127759464922200_LABEL': 'Check your email',
  'LABEL_32342199862391930_LABEL': 'We’ve just sent you an email with a link to reset your password. If you don’t receive it, check your junk folder.',
  'LINK_64563424514074200_LABEL': 'Back to sign in',
  'GIGYA_FORGOT_PASSWORD_SENT_SCREEN_CAPTION': 'Forgot Password',
  'SUBMIT_1640252814218_VALUE': 'Get The Code',
  'LABEL_1640252757841_LABEL': 'A code will be sent to your mobile phone number.',
  'GIGYA_MOBILE_FORGOT_PASSWORD_SCREEN_CAPTION': 'Forgot Password',
  'SUBMIT_1640253788797_VALUE': 'Verify',
  'LABEL_1640253752471_LABEL': 'Enter the code sent to your phone <a data-binding="true" data-switch-screen="gigya-mobile-forgot-password-screen"> {{accountInfo.phoneNumber}}\n <i class="gigya-pencil"></i></a>',
  'LABEL_1640253816271_LABEL': 'Didn\'t get the code? <a data-disable-on-render="true" data-gigya-api-link="accounts.otp.sendCode">Click Here</a>',
  'GIGYA_MOBILE_FORGOT_PASSWORD_VERIFICATION_SCREEN_CAPTION': 'Enter The Code',
  'SUBMIT_48518527117736720_VALUE': 'Save',
  'HEADER_141425801153815680_LABEL': 'Welcome back!',
  'LABEL_24798425325660336_LABEL': 'New password',
  'LABEL_31901611131936240_LABEL': 'Please change your password',
  'LINK_136193614115291570_LABEL': 'Cancel',
  'GIGYA_RESET_PASSWORD_SCREEN_CAPTION': 'Reset Password',
  'HEADER_23442591366731920_LABEL': 'Success!',
  'LABEL_43754194743648540_LABEL': 'Your new password has been set.',
  'LINK_122104312720648740_LABEL': '<input type="button" value="Sign in">',
  'GIGYA_RESET_PASSWORD_SUCCESS_SCREEN_CAPTION': 'Reset Password',
  'SUBMIT_65931489860940900_VALUE': 'Submit',
  'LABEL_16240548663414458_LABEL': 'For security reasons, your password needs changing:',
  'PASSWORD_101426512808638880_LABEL': 'Current password:',
  'PASSWORD_17036595420608126_LABEL': 'New password:',
  'PASSWORD_19612919353342308_LABEL': 'Confirm new password:',
  'GIGYA_PASSWORD_CHANGE_REQUIRED_SCREEN_CAPTION': 'Password Change',
  'SUBMIT_59040383411520670_VALUE': 'Submit',
  'LABEL_114745734160901840_LABEL': 'For your security, a verification email has been sent to you. Please follow the instructions in the\n email to verify your account.<br><br>To resend the verification email, please enter your email\n address below and click Submit.',
  'LABEL_135099439990555600_LABEL': '<a data-switch-screen="gigya-login-screen">Log in with a different account</a>',
  'GIGYA_VERIFICATION_PENDING_SCREEN_CAPTION': 'Email Verification',
  'TEXTBOX_53553186815043370_LABEL': 'Email:',
  'GIGYA_TFA_REGISTRATION_SCREEN_CAPTION': 'Securing Your Account',
  'GIGYA_TFA_VERIFICATION_SCREEN_CAPTION': 'Verifying Your Account',
  'LABEL_4529849558267223_LABEL': 'A verification email with a link to verify your account has been sent to you.',
  'LINK_19224603862200704_LABEL': '<input type="button" class="gigya-button" value="OK" tabindex="0">',
  'GIGYA_VERIFICATION_SENT_SCREEN_CAPTION': 'Email Verification',
  'SUBMIT_55211342470297420_VALUE': 'Submit',
  'CHECKBOX_78285840404140770_LABEL': 'I have read and understood the <a class="gigya-terms-of-use" href="#">Terms of Use</a>',
  'HEADER_51697213698355980_LABEL': 'Provide a password:',
  'PASSWORD_107891164873405600_PLACEHOLDER': 'Password',
  'PASSWORD_76732333762278900_PLACEHOLDER': 'Confirm password',
  'GIGYA_LITE_ACCOUNT_PROGRESSION_SCREEN_CAPTION': 'Complete Your Account',
  'HEADER_12404331492699160_LABEL': '<a href="/english/signin">Sign in</a>',
  'HEADER_127825712075138220_LABEL': '<a href="/english/register">Register</a>',
  'LABEL_149021791881608580_LABEL': 'Get access to Cambridge products and services by creating an account',
  'LABEL_45804971052437410_LABEL': 'By continuing, you’re agreeing with the',
  'LABEL_64722439462042540_LABEL': '&nbsp;&nbsp;and',
  'LINK_144261798651482500_LABEL': 'privacy notice',
  'LINK_148442579253917150_LABEL': 'privacy notice',
  'LINK_121919053331922690_LABEL': '<input type="button" value="Continue with email">',
  'LINK_152409823312012350_LABEL': 'privacy notice',
  'LINK_29000603564180040_LABEL': 'terms &amp; conditions',
  'GIGYA_PRE_REGISTER_SCREEN_CAPTION': 'Registration',
  'submit_142674579108303380_value': 'Continue',
  'header_153756110421239460_label': '<a href="/english/register">Register</a>',
  'header_57709528794549710_label': '<a href="/english/signin">Sign in</a>',
  'label_139998946623832940_label': 'Password',
  'label_48902362044111190_label': 'Or',
  'label_66482287771246690_label': 'Email address',
  'link_146308315993881860_label': 'Forgot password?',
  'gigya_login_screen_caption': 'Login',
  'submit_7667737432077126_value': 'Register now',
  'header_108728337563591200_label': '<a href="/english/register">Register</a>',
  'header_63695688742051540_label': '<a href="/english/signin">Sign in</a>',
  'label_103722341444128200_label': '&nbsp;&nbsp;and',
  'label_12306136368372000_label': 'Email address',
  'label_23360349088437170_label': 'Get access to Cambridge products and services by creating an account',
  'label_26425230947552150_label': 'Your country',
  'label_31875759361993188_label': 'By continuing, you’re agreeing with the',
  'label_43059823842119176_label': 'Password',
  'label_44483220097029120_label': 'Last name',
  'label_46301171957907990_label': 'First name',
  'link_114035836220497150_label': 'privacy notice',
  'link_55817364832410216_label': 'Back to register options',
  'link_75673091727259140_label': 'terms &amp; conditions',
  'gigya_register_screen_caption': 'Registration',
  'submit_31429658457676556_value': 'Submit',
  'header_67947632559311976_label': 'Complete your profile',
  'label_101783884719220270_label': '&nbsp;&nbsp;and',
  'label_130277852614992380_label': 'Your country',
  'label_28854870059218510_label': 'By continuing, you are agreeing to the',
  'link_15090096674456668_label': 'privacy notice',
  'link_56179583666790620_label': 'terms &amp; conditions',
  'gigya_complete_registration_screen_caption': 'Profile Completion',
  'textbox_115270360415748670_label': 'Last name',
  'textbox_136884197726350880_label': 'Email address',
  'textbox_65559603100946710_label': 'First name',
  'submit_314293454325435_value': 'Get The Code',
  'label_1555592368664_label': 'A code will be sent to your mobile phone number.',
  'gigya_mobile_login_screen_caption': 'Log In With Your Mobile Phone',
  'submit_31429890890890_value': 'Verify',
  'label_1555593146287_label': 'Enter the code sent to your phone <a data-binding="true" data-switch-screen="gigya-mobile-login-screen"> {{accountInfo.phoneNumber}}\n <i class="gigya-pencil"></i></a>',
  'label_546546790858_label': 'Didn\'t get the code? <a data-disable-on-render="true" data-gigya-api-link="accounts.otp.sendCode">Click Here</a>',
  'gigya_mobile_verification_screen_caption': 'Enter The Code',
  'submit_137794634987176350_value': 'Get The Code',
  'label_56426764264083580_label': 'A code will be sent to the new email for\n verification. If you don\'t validate with the code, your email will remain unchanged.',
  'gigya_change_email_screen_caption': 'Change Your Email',
  'textbox_20913098960397852_label': 'Email',
  'submit_106757411555670860_value': 'Verify',
  'label_124580104205672500_label': 'Enter the code sent to your email <a data-binding="true" data-switch-screen="gigya-change-email-screen"> {{accountInfo.email}}\n <i class="gigya-pencil"></i></a>',
  'label_45421446589093550_label': 'Didn\'t get the code? <a data-disable-on-render="true" data-gigya-api-link="accounts.otp.sendCode">Click Here</a>',
  'gigya_email_code_verification_screen_caption': 'Enter The Code',
  'submit_81725475708397440_value': 'Reset password',
  'header_101499678640920860_label': 'Reset your password',
  'label_19299858817415948_label': 'Email address',
  'label_53453282263825500_label': 'Please enter the email address linked to your account and we’ll send a link to reset your password',
  'link_125263109889403890_label': 'Back to sign in',
  'gigya_forgot_password_screen_caption': 'Forgot Password',
  'header_166127759464922200_label': 'Check your email',
  'label_32342199862391930_label': 'We’ve just sent you an email with a link to reset your password. If you don’t receive it, check your junk folder.',
  'link_64563424514074200_label': 'Back to sign in',
  'gigya_forgot_password_sent_screen_caption': 'Forgot Password',
  'submit_1640252814218_value': 'Get The Code',
  'label_1640252757841_label': 'A code will be sent to your mobile phone number.',
  'gigya_mobile_forgot_password_screen_caption': 'Forgot Password',
  'submit_1640253788797_value': 'Verify',
  'label_1640253752471_label': 'Enter the code sent to your phone <a data-binding="true" data-switch-screen="gigya-mobile-forgot-password-screen"> {{accountInfo.phoneNumber}}\n <i class="gigya-pencil"></i></a>',
  'label_1640253816271_label': 'Didn\'t get the code? <a data-disable-on-render="true" data-gigya-api-link="accounts.otp.sendCode">Click Here</a>',
  'gigya_mobile_forgot_password_verification_screen_caption': 'Enter The Code',
  'submit_48518527117736720_value': 'Save',
  'header_141425801153815680_label': 'Welcome back!',
  'label_24798425325660336_label': 'New password',
  'label_31901611131936240_label': 'Please change your password',
  'link_136193614115291570_label': 'Cancel',
  'gigya_reset_password_screen_caption': 'Reset Password',
  'header_23442591366731920_label': 'Success!',
  'label_43754194743648540_label': 'Your new password has been set.',
  'link_122104312720648740_label': '<input type="button" value="Sign in">',
  'gigya_reset_password_success_screen_caption': 'Reset Password',
  'submit_65931489860940900_value': 'Submit',
  'label_16240548663414458_label': 'For security reasons, your password needs changing:',
  'password_101426512808638880_label': 'Current password:',
  'password_17036595420608126_label': 'New password:',
  'password_19612919353342308_label': 'Confirm new password:',
  'gigya_password_change_required_screen_caption': 'Password Change',
  'submit_59040383411520670_value': 'Submit',
  'label_114745734160901840_label': 'For your security, a verification email has been sent to you. Please follow the instructions in the\n email to verify your account.<br><br>To resend the verification email, please enter your email\n address below and click Submit.',
  'label_135099439990555600_label': '<a data-switch-screen="gigya-login-screen">Log in with a different account</a>',
  'gigya_verification_pending_screen_caption': 'Email Verification',
  'textbox_53553186815043370_label': 'Email:',
  'gigya_tfa_registration_screen_caption': 'Securing Your Account',
  'gigya_tfa_verification_screen_caption': 'Verifying Your Account',
  'label_4529849558267223_label': 'A verification email with a link to verify your account has been sent to you.',
  'link_19224603862200704_label': '<input type="button" class="gigya-button" value="OK" tabindex="0">',
  'gigya_verification_sent_screen_caption': 'Email Verification',
  'submit_55211342470297420_value': 'Submit',
  'checkbox_78285840404140770_label': 'I have read and understood the <a class="gigya-terms-of-use" href="#">Terms of Use</a>',
  'header_51697213698355980_label': 'Provide a password:',
  'password_107891164873405600_placeholder': 'Password',
  'password_76732333762278900_placeholder': 'Confirm password',
  'gigya_lite_account_progression_screen_caption': 'Complete Your Account',
  'header_12404331492699160_label': '<a href="/english/signin">Sign in</a>',
  'header_127825712075138220_label': '<a href="/english/register">Register</a>',
  'label_149021791881608580_label': 'Get access to Cambridge products and services by creating an account',
  'label_45804971052437410_label': 'By continuing, you’re agreeing with the',
  'label_64722439462042540_label': '&nbsp;&nbsp;and',
  'link_121919053331922690_label': '<input type="button" value="Continue with email">',
  'link_152409823312012350_label': 'privacy notice',
  'link_29000603564180040_label': 'terms &amp; conditions',
  'gigya_pre_register_screen_caption': 'Registration',
  'there_is_no_user_with_that_username_or_email': 'Email address not registered.',
  'invalid_login_or_password': 'Your email or password was entered incorrectly.',
  'this_field_is_required': 'This field is required',
  'email_address_is_invalid': 'E-mail address is invalid.'
}