import getCountries from '~/graphql/queries/data/get-countries.gql'
import Country from '~/interfaces/country'

export default async({ app, store }: any) => {
  const client = app.apolloProvider.defaultClient

  const response = await client.query({ query: getCountries })
  const countries = response.data.countriesCollection.items[0].jsonList
  // Using localeCompare for string sorting, for language-specific
  // such as diacritics or varying alphabets, resulting in accurate ordering.
  countries.sort((a: Country, b: Country) =>
    a.countryName.localeCompare(b.countryName, 'en', { sensitivity: 'base' }))

  store.dispatch('list/setCountries', countries)
}