import { Vue } from 'nuxt-property-decorator'
import UAParser from 'ua-parser-js'
import mixinFormatText from '~/mixins/formatText'

export default Vue.extend({
  mixins: [mixinFormatText],
  data() {
    return {
      cookieName: 'npsPopupCookie'
    }
  },
  methods: {
    shouldNpsPopupDisplay(): boolean {
      const minSiteVisitCount = this.$store.state.list.npsPopupSettings['Minimum site visits needed to view popup']
      const npsPopupPercentage = this.$store.state.list.npsPopupSettings['Percentage of users that can view popup']
      const minDaysToShowPopup =
        this.$store.state.list.npsPopupSettings['Show popup at least after X days since last view']
      const npsPopupRandomizer = Math.floor(Math.random() * 100)
      const npsPopupCookie = this.getCookie(this.cookieName)
      const userAgent = navigator.userAgent
      if (npsPopupRandomizer >= npsPopupPercentage) {
        return false
      }
      if (npsPopupCookie[userAgent] <= minSiteVisitCount) {
        return false
      }
      if (this.isNpsPopupDisplayedInXDays(minDaysToShowPopup, npsPopupCookie)) {
        return false
      }
      return true
    },
    displayNpsPopup() {
      const npsPopupDelay = this.$store.state.list.npsPopupSettings['Show popup after X seconds']
      const npsPopupCookie = this.getCookie(this.cookieName)
      const userAgentParser = new UAParser(navigator.userAgent)
      const params = {
        'Touchpoint': 'English Digital Shop NPS pop-up',
        'Country': this.$store.state.country.countryName,
        'Email': this.$store.state.user?.user?.email,
        'Name': this.$store.state.user?.user?.firstName,
        'Surname': this.$store.state.user?.user?.lastName,
        'Login Type': 'Manual login via English Digital Shop',
        'Operating system used': userAgentParser.getOS().name,
        'Browser': userAgentParser.getBrowser().name
      }
      setTimeout(() => {
        // @ts-ignore
        CG.init({
          'surveyId': this.$config.npsSurveyId,
          'tenant': 'customergauge',
          'region': 'eu',
          'language': this.fromKebabToSnakeCase(this.$store.state.localisation.locale ?? 'en_GB'),
          params
        })
        const value = {
          ...npsPopupCookie,
          'npsPopupDisplayedDate': new Date().getTime()
        }
        this.setCookie(this.cookieName, value)
      }, npsPopupDelay * 1000)
    },
    isNpsPopupDisplayedInXDays(minDaysToShowPopup: number, npsPopupCookie: any): boolean {
      if (!npsPopupCookie.npsPopupDisplayedDate) {
        return false
      }
      const currentDate: any = new Date()
      const npsPopupDisplayedDate: any = new Date(npsPopupCookie.npsPopupDisplayedDate)
      const timeDifference = currentDate - npsPopupDisplayedDate
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24)
      return daysDifference <= minDaysToShowPopup
    },
    setCookie(name: string, value: any) {
      this.$cookies.set(
        name,
        value,
        {
          secure: true,
          path: '/',
          maxAge: 365 * 60 * 60 * 24
        }
      )
    },
    getCookie(name: string) {
      return this.$cookies.get(name)
    },
    saveUserAgentCount() {
      sessionStorage.setItem('sessionData', 'true')
      let npsPopupCookie = this.getCookie(this.cookieName)
      const userAgent = navigator.userAgent
      if (!npsPopupCookie) {
        npsPopupCookie = {
          [userAgent]: 1,
          npsPopupDisplayedDate: null
        }
      } else {
        const userAgentCount = npsPopupCookie[userAgent] || 0
        npsPopupCookie = {
          ...npsPopupCookie,
          [userAgent]: userAgentCount + 1
        }
      }
      this.setCookie(this.cookieName, npsPopupCookie)
    },
    isNewSessionStarted(): boolean {
      const isSessionExists = sessionStorage.getItem('sessionData')
      if (isSessionExists === 'true') {
        return false
      }
      return true
    }
  }
})