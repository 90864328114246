import BasketProps from '~/interfaces/basket-props'
import Context from '~/interfaces/context'
import Product from '~/interfaces/product'
import ProductCardData from '~/interfaces/product-card-data'

import basketCheckoutService from '~/services/basket-checkout-service'
import basketMergeService from '~/services/basket-merge-service'
import basketSyncService from '~/services/basket-sync-service'
import basketUpdateService from '~/services/basket-update-service'
import basketBuyNowService from '~/services/basket-buy-now-service'

import helperService from '~/services/helpers-service'

import ebookObjectKeysService from '~/services/ebook-object-keys-service'
import orderHistoryService from '~/services/order-history-service'
import { NewRelicService } from '~/services/new-relic-service'

export default ({
  $config,
  store,
  app
}: Context, inject: any) => {
  const basketProps: BasketProps = {
    apiKey: $config.eCommerceBasketApiKey,
    apiUrl: $config.eCommerceBasketApiUrl,
    checkoutUrl: $config.eCommerceCheckoutUrl,
    storeId: $config.eCommerceStoreId
  }

  inject('basketCheckout', () => basketCheckoutService(
    app.$axios, basketProps, store, app.$cookies, app.$newRelic
  ))
  inject('basketMerge', () => basketMergeService(app.$axios, basketProps, store))
  inject('basketSync', () => basketSyncService(app.$axios, basketProps, store))
  inject('basketUpdate', (product: Product | ProductCardData) => basketUpdateService(
    app.$axios, basketProps, store, product, app.$newRelic
  ))
  inject('basketBuyNow', (product: Product | ProductCardData) => basketBuyNowService(
    app.$axios, basketProps, store, app.$cookies, product, app.$newRelic
  ))
  inject('cloneNestedArray', (items: any[]) => helperService.cloneNestedArray(items))
  inject('t', (text: string): string => store.getters['localisation/translate'](text, $config.env))
  inject('orderHistory', (limit?: number) => orderHistoryService(limit, store))
  inject('getEbookObjectKeys', () => ebookObjectKeysService(app))
  inject('newRelic', new NewRelicService({ debug: false }))
}