
  import { Vue } from 'nuxt-property-decorator'
  import mixinSetGigyaBehaviors from '~/mixins/setGigyaBehaviors'
  import { NRAction } from '~/constants/newrelic-action'

  export default Vue.extend({
    mixins: [mixinSetGigyaBehaviors],
    props: {
      user: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        NRAction,
      }
    },
    mounted() {
      gigya.accounts.addEventHandlers({ onLogout: this.onLogout })
    },
    methods: {
      goToRoute(page) {
        window.location.href = window.location.origin + page
      },
      async logout() {
        this.$emit('toggleMobileNav')
        await gigya.accounts.logout()
      },
      async onLogout() {
        await this.saveLogout()
        this.goToRoute('/home')
      }
    }
  })
