import { render, staticRenderFns } from "./HeaderMenuAccount.vue?vue&type=template&id=ed3e78ae&scoped=true&"
import script from "./HeaderMenuAccount.vue?vue&type=script&lang=js&"
export * from "./HeaderMenuAccount.vue?vue&type=script&lang=js&"
import style0 from "./HeaderMenuAccount.vue?vue&type=style&index=0&id=ed3e78ae&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed3e78ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserInitialsIcon: require('/home/runner/work/english-digital-shop-frontend/english-digital-shop-frontend/components/User/UserInitialsIcon.vue').default,PopupMenuAccount: require('/home/runner/work/english-digital-shop-frontend/english-digital-shop-frontend/components/Popup/PopupMenuAccount.vue').default,PopupMenuAccountMobile: require('/home/runner/work/english-digital-shop-frontend/english-digital-shop-frontend/components/Popup/PopupMenuAccountMobile.vue').default})
