export default ({ app, store }: any) => {
  const countries = store.state.list.countries
  const countryCode = store.state.country.countryCodeIso2 ||
    app.$cookies.get('CF-IPCountry', { fromRes: true }) ||
    countries.find((entry: any) => entry.countryCodeIso3 === 'GBR').countryCodeIso2 ||
    'GB'
  const countryRecord = countries.find((entry: any) => entry.countryCodeIso2 === countryCode)
  updateCountryStore(store, countryRecord)
}

function updateCountryStore(
  store: any,
  country: Record<string, string>
) {
  store.dispatch('country/setCountryCode', country.countryCodeIso2)
  store.dispatch('country/setCountryName', country.countryName)
  store.dispatch('country/setCountryCurrencyCode', country.currency)
  store.dispatch('country/setKonakartCurrencyCode', country.currencyKonakart)
  store.dispatch('country/setSalesOrganisation', country.salesOrganisation)
  store.dispatch('country/setLocalCountryCurrency', country?.countryCurrency ?? undefined)
}