
  import { Vue } from 'nuxt-property-decorator'
  import { mapState } from 'vuex'
  import Country from '~/interfaces/country'
  import mixinFormatPriceDisplay from '~/mixins/formatPrice'
  import mixinFormatProduct from '~/mixins/formatProduct'
  import mixinFormatUrl from '~/mixins/formatUrl'
  import mixinGetProductImageUrl from '~/mixins/getProductImageUrl'

  export default Vue.extend({
    mixins: [
      mixinFormatPriceDisplay,
      mixinFormatProduct,
      mixinFormatUrl,
      mixinGetProductImageUrl,
    ],
    computed: {
      country(): Country {
        return this.$store.state.country
      },
      ...mapState({
        showPopupAddedToBasket: 'showPopupAddedToBasket',
        addedToBasketItem: 'addedToBasketItem',
        showGoToBasketLoader: 'showGoToBasketLoader'
      })
    },
    watch: {
      addedToBasketItem(newValue) {
        if (newValue) {
          const closeButton = this.$refs.closeBtn as any
          setTimeout(() => closeButton.focus(), 50)
        }
      }
    },
    methods: {
      closePopup(): void {
        this.$store.dispatch('setShowPopupAddedToBasket', false)
      },
      async goToBasket() {
        try {
          this.$store.dispatch('setShowGoToBasketLoader', 'popup')
          await this.$basketCheckout()
          this.$store.dispatch('setShowGoToBasketLoader', null)
        } catch (error) {
          this.$store.dispatch('setShowGoToBasketLoader', null)
        }
      }
    }
  })
