export enum NRAction {
  AddToBasketClick = 'Add to basket Click',
  AddToBasketSuccess = 'Add to basket Success',
  AddToBasketError = 'Add to basket Fail',
  BuyNowClick = 'Buy now Click',
  BuyNowSuccess = 'Buy now Success',
  BuyNowError = 'Buy now Fail',
  GoToBasket = 'Go to basket',
  GoToSignInPage = 'Go to sign in',
  GoToRegisterPage = 'Go to register',
  RegisterNow = 'Register now',
  SignIn = 'Sign in',
  SignOut = 'Sign Out',
  ForgotPassword = 'Forgot password',
  ManageMyAccount = 'Manage my account',
  ResetPassword = 'Reset password',
}