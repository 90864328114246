export const state = () => ({
  maintenance: {
    enabled: false,
    messaging: ''
  },
  ecommerce: {
    enabled: true,
    messaging: ''
  },
  preview: false
});

export const mutations = {
  SET_MAINTENANCE_SETTINGS(state, settings) {
    state.maintenance.enabled = settings.enabled;
    state.maintenance.messaging = settings.messaging;
  },
  SET_ECOMMERCE_SETTINGS(state, settings) {
    state.ecommerce.enabled = settings.enabled;
    state.ecommerce.messaging = settings.messaging;
  },
  SET_PREVIEW_SETTING(state, setting) {
    state.preview = setting;
  }
};

export const actions = {
  setMaintenanceSettings({ commit }, settings) {
    commit('SET_MAINTENANCE_SETTINGS', { ...settings });
  },
  setEcommerceSettings({ commit }, settings) {
    commit('SET_ECOMMERCE_SETTINGS', { ...settings });
  },
  setPreview({ commit }, setting) {
    commit('SET_PREVIEW_SETTING', setting);
  }
};