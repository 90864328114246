export const state = () => ({
  customerId: null
});

export const mutations = {
  SET_CUSTOMER_ID(state, customerId) {
    state.customerId = customerId;
  }
};

export const actions = {
  setCustomerId({ commit }, customerId) {
    commit('SET_CUSTOMER_ID', customerId);
  }
};