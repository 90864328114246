import Vue from 'vue'

export default () => {
  Vue.directive('click-outside', {
    bind(el: any, binding: any) {
      el.clickOutsideEvent = (event: any) => {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value.fn(binding.value.args)
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind(el: any) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  })
  Vue.directive('mousedown-outside', {
    bind(el: any, binding: any) {
      el.mousedownOutsideEvent = (event: any) => {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value.fn(binding.value.args)
        }
      }
      document.body.addEventListener('mousedown', el.mousedownOutsideEvent)
    },
    unbind(el: any) {
      document.body.removeEventListener('mousedown', el.mousedownOutsideEvent)
    },
  })
}