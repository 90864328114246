
  import { Vue } from 'nuxt-property-decorator'
  import Country from '~/interfaces/country'
  import mixinFormatText from '~/mixins/formatText'
  import mixinDebounce from '~/mixins/debounce'

  export default Vue.extend({
    mixins: [mixinFormatText, mixinDebounce],
    data():{
      countries: Country[],
      filteredCountries: Country[],
      selectedCountryData: Country | null,
      selectedCountryName: string,
      showDropdown: boolean,
      showDropdownOnTab: boolean
    } {
      return {
        countries: this.$store.state.list.countries,
        filteredCountries: this.$store.state.list.countries,
        selectedCountryData: null,
        selectedCountryName: '',
        showDropdown: false,
        showDropdownOnTab: false
      }
    },
    watch: {
      selectedCountryName: {
        immediate: true,
        handler() {
          this.emitSelectedCountry()
        }
      }
    },
    beforeMount() {
      this.selectedCountryData = this.getSelectedCountryData()
      this.selectedCountryName = this.selectedCountryData.countryName
    },
    methods: {
      toggleDropdown() {
        this.showDropdown = !this.showDropdown
        const countrySearchInput = this.$refs.countrySearchInputRef as HTMLInputElement
        if (this.showDropdown) {
          countrySearchInput.focus()
        } else {
          this.showDropdownOnTab = false
          countrySearchInput.blur()
        }
      },
      getSelectedCountryData(): Country {
        return {
          countryName: this.$store.state.country.countryName,
          countryCodeIso2: this.$store.state.country.countryCodeIso2,
          countryCodeIso3: '',
          currency: this.$store.state.country.currency,
          salesOrganisation: this.$store.state.country.salesOrganisation,
          currencyKonakart: this.$store.state.country.currencyKonakart,
          region: '',
          countryCurrency: this.$store.state.country?.countryCurrency ?? undefined,
        }
      },
      clearSelectedCountryData() {
        this.selectedCountryData = {
          countryName: '',
          countryCodeIso2: '',
          countryCodeIso3: '',
          currency: '',
          salesOrganisation: '',
          currencyKonakart: '',
          region: '',
          countryCurrency: undefined
        }
      },
      setCountry(event: any, selectedCountry: Country): void {
        this.selectedCountryData = selectedCountry
        this.selectedCountryName = selectedCountry.countryName
        this.showDropdown = false
        this.showDropdownOnTab = false
        this.emitSelectedCountry()
        event.stopPropagation()
      },
      emitSelectedCountry(): void {
        this.$emit('selectedCountry', this.selectedCountryData)
      },
      async onInputChange() {
        await this.debounceWrapper(200, () => {
          if (!this.showDropdown && this.selectedCountryName.length > 0) {
            this.showDropdown = true
          }
          this.filteredCountries = this.countries?.filter(
            (country: Country) => {
              const selectedCountryName = this.selectedCountryName.trim().toLowerCase()
              if (!selectedCountryName) { return true }
              const isoFilter = this.evaluateISO(country, selectedCountryName)
              const countryNameFilter = this.evaluateCountryName(country, selectedCountryName)
              return isoFilter || countryNameFilter
            }
          )
        })
      }, 
      onInputKeyDown(event: any): void {
        this.showDropdownOnTab = (event.keyCode === 9 && !event.shiftKey)

        if (event.keyCode === 40) {
          event.preventDefault()
          const dropdownList = this.getDropdownListElements()
          if (dropdownList.length > 0) {
            this.showDropdownOnTab = true
            dropdownList[0].focus()
          }
        } else if (event.keyCode === 27) {
          const countrySearchInput = this.$refs.countrySearchInputRef as any
          countrySearchInput.blur()
          this.showDropdown = false
        }
        this.clearSelectedCountryData()
      },
      onListKeyDown(event: any, option: any = {}): void {
        const {
          countryIndex, filteredCountries
        } = option
        const dropdownList = this.getDropdownListElements()

        if (event.keyCode === 13 && filteredCountries.length < 1) {
          this.showDropdownOnTab = false
        } else if (event.keyCode === 13) {
          const selectedCountry = filteredCountries[countryIndex]
          this.setCountry(event, selectedCountry)
        } else if (event.keyCode === 9 && !event.shiftKey && (
          (countryIndex + 1) === filteredCountries.length || filteredCountries.length < 1
        )) {
          this.showDropdownOnTab = false
        } else if (event.keyCode === 38) {
          event.preventDefault()
          if (countryIndex < 1) {
            const countrySearchInput = this.$refs.countrySearchInputRef as any
            countrySearchInput.focus()
          } else {
            dropdownList[countryIndex - 1].focus()
          }
        } else if (event.keyCode === 40) {
          event.preventDefault()
          if (countryIndex < (filteredCountries.length - 1)) {
            dropdownList[countryIndex + 1].focus()
          }
        } else if (event.keyCode === 27) {
          this.showDropdownOnTab = false
          this.showDropdown = false
        }
      },
      getDropdownListElements() {
        const languagesListEl = this.$refs.countryListRef as any
        return languagesListEl.querySelectorAll('li')
      },
      evaluateISO(country: Country, search: string): boolean {
        const iso2 = country?.countryCodeIso2?.toLocaleLowerCase()
        const iso3 = country?.countryCodeIso3?.toLocaleLowerCase()
        return [iso2, iso3].includes(search)
      },
      evaluateCountryName(country: Country, search: string): boolean {
        const countryName = country.countryName.toLocaleLowerCase()
        if (this.containsDiacriticChar(search)) {
          return countryName.includes(search)
        }
        return this.normalizeDiacritic(countryName).includes(search)
      }
    }
  })
