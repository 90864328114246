var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"country-search-container"},[_c('label',{staticClass:"country-input-label eds-label-regular",attrs:{"for":"country-search-input-ele"}},[_vm._v("\n    "+_vm._s(_vm.$t('Location'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"mt-1"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
        fn: () => {
          _vm.showDropdown = false
          _vm.showDropdownOnTab = _vm.showDropdown ? _vm.showDropdownOnTab : false
        }
      }),expression:"{\n        fn: () => {\n          showDropdown = false\n          showDropdownOnTab = showDropdown ? showDropdownOnTab : false\n        }\n      }"}],staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCountryName),expression:"selectedCountryName"}],ref:"countrySearchInputRef",staticClass:"country-search-input",class:{
          'focus': _vm.showDropdown || _vm.showDropdownOnTab
        },attrs:{"id":"country-search-input-ele","autocomplete":"off","tabindex":"0"},domProps:{"value":(_vm.selectedCountryName)},on:{"keydown":function($event){return _vm.onInputKeyDown($event)},"focus":function($event){_vm.showDropdown = true},"input":[function($event){if($event.target.composing)return;_vm.selectedCountryName=$event.target.value},function($event){return _vm.onInputChange()}]}}),_vm._v(" "),_c('button',{staticClass:"input-caret",attrs:{"tabindex":"-1"},on:{"click":_vm.toggleDropdown}},[_c('img',{attrs:{"src":require("assets/icons/caret.svg"),"alt":"input-caret","width":"12","height":"6"}})]),_vm._v(" "),_c('div',{staticClass:"input-bottom-bar"})]),_vm._v(" "),_c('div',{staticClass:"countries-dropdown",class:{ 'active': _vm.showDropdown || _vm.showDropdownOnTab }},[_c('ul',{ref:"countryListRef"},[(!_vm.filteredCountries.length)?_c('li',{attrs:{"tabindex":"0"},on:{"keydown":function($event){return _vm.onListKeyDown($event, { countryIndex: 0, filteredCountries: [] })}}},[_vm._v("\n          "+_vm._s(_vm.$t('No Result'))+"\n        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.filteredCountries),function(country,countryIndex){return _c('li',{key:country.countryName + '-' + countryIndex,attrs:{"tabindex":"0"},on:{"mousedown":function($event){return _vm.setCountry($event, country)},"keydown":function($event){return _vm.onListKeyDown($event, { countryIndex, filteredCountries: _vm.filteredCountries })}}},[_vm._v("\n          "+_vm._s(country.countryName)+"\n        ")])})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }