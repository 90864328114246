
  import { Vue } from 'nuxt-property-decorator'

  export default Vue.extend({
    props: {
      isMobile: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        isMenuActive: false
      }
    },
    computed: {
    },
    methods: {
      toggleMenuDropdown() {
        this.isMenuActive = !this.isMenuActive
      },
      toggleMenuClose() {
        this.isMenuActive = false
        if (this.isMobile) {
          this.$emit('toggleMobileNav')
        } else {
          // called blur() when location menu dropdown is closed by scrolling
          const locationBtn = this.$refs.locationBtn as HTMLElement
          if (locationBtn) { locationBtn.blur() }
        }
      }
    }
  })
