var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"btn-cart",class:{
    'visible-count': _vm.basketCount > 0,
    'loading': _vm.showGoToBasketLoader === 'header'
  },on:{"click":_vm.goToBasket}},[_c('img',{class:{
      'loading-icon': _vm.showGoToBasketLoader === 'header'
    },attrs:{"alt":_vm.basketCount > 0 ? `Added to your basket ${_vm.basketCount} ${_vm.item}` : `${_vm.altText}`,"width":"20","height":"18"}}),_vm._v(" "),_c('span',{class:{
      'hidden': !_vm.basketCount || _vm.showGoToBasketLoader === 'header'
    }},[_vm._v("\n    "+_vm._s(_vm.basketCount && _vm.basketCount > 9 ? '' : _vm.basketCount)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }