import getNpsPopupSettings from '~/graphql/queries/data/get-nps-popup-settings.gql'
import NpsPopupSetting from '~/interfaces/nps-popup-setting'

export default async({ app, store }: any) => {
  const client = app.apolloProvider.defaultClient

  const npsPopupSettinngsResponse = await client.query({
    query: getNpsPopupSettings
  })
  const npsPopupSettings: [NpsPopupSetting] = npsPopupSettinngsResponse?.data?.edsNpsPopupSettingsCollection?.items
  const content: any = {}
  for (const item of npsPopupSettings) {
    content[item.npsSetting] = item.value
  }
  store.dispatch('list/setNpsPopupSettings', content)
}