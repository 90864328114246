import CountryCode from '~/interfaces/country-code'

const countryCodes: CountryCode[] = [
  {
    iso: 'GB', currency: 'GBP', symbol: '£'
  },
  {
    iso: 'EU', currency: 'EUR', symbol: '€'
  },
  {
    iso: 'US', currency: 'USD', symbol: '$'
  },
  {
    iso: 'CA', currency: 'CAD', symbol: '$'
  },
  {
    iso: 'AU', currency: 'AUD', symbol: '$'
  },
  {
    iso: 'NZ', currency: 'NZD', symbol: '$'
  },
  {
    iso: 'US', currency: 'USD-E', symbol: '$'
  },
  {
    iso: 'US', currency: 'USD-N', symbol: '$'
  }
]

export default countryCodes