
  import { Vue } from 'nuxt-property-decorator'
  import { sanitizeUrl } from '@braintree/sanitize-url'
  import HeaderMenu from '~/interfaces/header-menu'
  import mixinHandleLinkClick from '~/mixins/handleLinkClick'

  export default Vue.extend({
    mixins: [mixinHandleLinkClick],
    props: {
      menu: {
        type: Array as () => Array<HeaderMenu>,
        default() { return [] }
      }
    },
    data() {
      return {
        bannerHeight: this.getPopupContainerHeight()
      }
    },
    computed: {
      bannerEnabled(): boolean {
        return this.$store.state.settings.maintenance.enabled
      },
      bannerChanges(): boolean {
        return this.bannerEnabled
      }
    },
    watch: {
      bannerChanges() {
        this.resizePopupContainer()
      }
    },
    mounted() {
      this.resizePopupContainer()
      window.addEventListener('resize', this.resizePopupContainer)
    },
    methods: {
      sanitizeUrl,
      toggleMobileNav() {
        this.$emit('toggleMobileNav')
      },
      toggleMenu(index: number): void {
        this.menu.forEach((item: HeaderMenu, menuIndex: number) => {
          if (menuIndex === index) {
            item.active = !item.active
          }
        })
      },
      redirectLink(link: string): void {
        window.location.href = link
      },
      getPopupContainerHeight() {
        const maintenanceBanner = document.querySelector('.maintenance-banner') as HTMLElement
        if (this.bannerEnabled && maintenanceBanner) {
          const newHeight = window.innerHeight - maintenanceBanner.scrollHeight
          return newHeight + 'px'
        } else {
          return '100%'
        }
      },
      resizePopupContainer() {
        this.bannerHeight = this.getPopupContainerHeight()
      }
    }
  })
