export default {
  methods: {
    getMaintenanceBannerHeight(enableBanner: boolean): string {
      const maintenanceBanner: HTMLElement | null = document.querySelector('.maintenance-banner')
      if (enableBanner && maintenanceBanner) {
        return maintenanceBanner.offsetHeight + 'px'
      } else {
        return '0px'
      }
    }
  }
}