/* eslint no-shadow: ["error", { "allow": ["state"] }] */
export const state = () => ({
  countryCodeIso2: null,
  countryName: null,
  currency: null,
  salesOrganisation: null,
  countryCurrency: undefined
});

export const mutations = {
  SET_COUNTRY_CODE(state, countryCodeIso2) {
    state.countryCodeIso2 = countryCodeIso2;
  },
  SET_COUNTRY_NAME(state, countryName) {
    state.countryName = countryName;
  },
  SET_COUNTRY_CURRENCY_CODE(state, currency) {
    state.currency = currency;
  },
  SET_KONAKART_CURRENCY_CODE(state, currencyKonakart) {
    state.currencyKonakart = currencyKonakart;
  },
  SET_SALES_ORGANISATION(state, salesOrganisation) {
    state.salesOrganisation = salesOrganisation;
  },
  SET_LOCAL_COUNTRY_CURRENCY(state, countryCurrency) {
    state.countryCurrency = countryCurrency;
  }
};

export const actions = {
  setCountryCode({ commit }, countryCodeIso2) {
    commit('SET_COUNTRY_CODE', countryCodeIso2);
  },
  setCountryName({ commit }, countryName) {
    commit('SET_COUNTRY_NAME', countryName);
  },
  setCountryCurrencyCode({ commit }, currency) {
    commit('SET_COUNTRY_CURRENCY_CODE', currency);
  },
  setKonakartCurrencyCode({ commit }, currency) {
    commit('SET_KONAKART_CURRENCY_CODE', currency);
  },
  setSalesOrganisation({ commit }, salesOrganisation) {
    commit('SET_SALES_ORGANISATION', salesOrganisation);
  },
  setLocalCountryCurrency({ commit }, currency) {
    commit('SET_LOCAL_COUNTRY_CURRENCY', currency);
  }
};