import getCategories from '~/graphql/queries/data/get-categories.gql'

export default async({ app, store }: any) => {
  const client = app.apolloProvider.defaultClient

  const categoryResponse = await client.query({
    query: getCategories,
    variables: {
      preview: store.state.settings.preview
    }
  })
  const categories = categoryResponse.data.edsCategoriesCollection.items

  store.dispatch('list/setCategories', categories)
}