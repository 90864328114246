import {
  getBasketItems, updateBasketItems, validateTempCustomerId
} from './basket-common-service'
import { NewRelicService } from './new-relic-service'
import Product from '~/interfaces/product'
import ProductCardData from '~/interfaces/product-card-data'
import BasketProps from '~/interfaces/basket-props'
import { NRAction } from '~/constants/newrelic-action'

export default async function(
  axios: any,
  basketProps: BasketProps,
  $store: any,
  product: Product | ProductCardData,
  newRelic: NewRelicService
) {
  $store.dispatch('setIsAddingToBasket', true)
  try {
    if ($store.state.customer.customerId) {
      const headers = {
        common: {
          'x-api-key': basketProps.apiKey
        }
      }
      const loginToken = $store.state.user?.token || null
      const loginUser = $store.state.user?.user || null
      if (loginToken && loginUser) {
        axios.setToken(loginToken, 'Bearer')
      }
      const storeId = basketProps.storeId

      const customerId = await validateTempCustomerId(
        axios, basketProps, headers, $store.state.customer.customerId
      )

      await updateBasketItems(
        axios, basketProps, headers, customerId, storeId, product.sapMaterialNumber
      )
      const basket = await getBasketItems(axios, basketProps, headers, customerId, storeId) as unknown as any[]
      newRelic.interActionText(NRAction.AddToBasketSuccess)
      $store.dispatch('list/emptyBasket')

      for (const item of basket) {
        $store.dispatch('list/addToBasket', {
          sapMaterialNumber: item.product.sku,
          quantity: item.quantity
        })
      }
      $store.dispatch('setShowPopupAddedToBasket', true)
      $store.dispatch('setAddedToBasketItem', product)
    }
  } catch (response: any) {
    newRelic.interActionText(NRAction.AddToBasketError)
    $store.dispatch('setSapForAddToBasketError', product.sapMaterialNumber)
    $store.dispatch('setShowAddToBasketErrorPopup', true)
    // eslint-disable-next-line no-console
    console.log(response.message, 'There was an error in the POST basket items API call.')
  } finally {
    $store.dispatch('setIsAddingToBasket', false)
  }
}