import Context from '~/interfaces/context'

export default ({ $config }: Context) => ({
  httpEndpoint: `https://graphql.contentful.com/content/v1/spaces/${$config.contentfulSpaceId}` +
    `/environments/${$config.contentfulEnvironment}`,
  httpLinkOptions: {
    headers: {
      Authorization: `Bearer ${$config.contentfulAccessToken}`
    }
  }
})