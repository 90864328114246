
  import { Vue } from 'nuxt-property-decorator'
  import mixinSetGigyaBehaviors from '~/mixins/setGigyaBehaviors'
  import mixinGetMaintenanceBehaviors from '~/mixins/getMaintenanceBehaviors'
  import { NRAction } from '~/constants/newrelic-action'

  export default Vue.extend({
    mixins: [
      mixinSetGigyaBehaviors,
      mixinGetMaintenanceBehaviors
    ],
    props: {
      user: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        NRAction,
        calculatedTopValue: this.getMaintenanceBannerHeight(this.$store.state.settings.maintenance.enabled)
      }
    },
    beforeMount() {
      window.addEventListener('scroll', this.toggleMenu)
    },
    mounted() {
      gigya.accounts.addEventHandlers({ onLogout: this.onLogout })
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.toggleMenu)
    },
    methods: {
      goToRoute(page) {
        this.toggleMenu()
        window.location.href = window.location.origin + page
      },
      toggleMenu() {
        this.$emit('toggleMenu')
      },
      async logout() {
        await gigya.accounts.logout()
      },
      async onLogout() {
        await this.saveLogout()
        this.goToRoute('/home')
      }
    }
  })
