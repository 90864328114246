import getHeaderNavigations from '~/graphql/queries/data/get-header-navigations.gql'
import HeaderMenu, {
  NavigationResponse, HeaderSubMenu, Navigation
} from '~/interfaces/header-menu'
import formatUrl from '~/mixins/formatUrl'

const headerMenuOrder = [
  {
    grouping: 'Categories',
    subGrouping: ['Age / Education Level', 'Learning Type', 'Exam']
  },
  {
    grouping: 'Our Products',
    subGrouping: []
  },
  {
    grouping: 'Help',
    subGrouping: []
  }
]

const productRanges: string[] = []

const sortHeaderNavigations = (navigations: Navigation[]) => {
  const sortedNavigations = navigations.sort((a: Navigation, b: Navigation) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) { return -1 }
    if (a.label.toLowerCase() > b.label.toLowerCase()) { return 1 }
    return 0
  })
  return sortedNavigations
}

const sortHeaderSubMenu = (subMenu: HeaderSubMenu[], subMenuOrder: string[]) => {
  const sortedSubMenu = subMenu.sort((a: HeaderSubMenu, b: HeaderSubMenu) => {
    const aItemIndex = subMenuOrder.findIndex(subItem => subItem.toLowerCase() === a?.groupName?.toLowerCase())
    const bItemIndex = subMenuOrder.findIndex(subItem => subItem.toLowerCase() === b?.groupName?.toLowerCase())
    return ((aItemIndex || 0) + 1) - ((bItemIndex || 0) + 1)
  })
  return sortedSubMenu
}

const sortHeaderMenu = (menu: HeaderMenu[]) => {
  const sortedMenu = menu.sort((a: HeaderMenu, b: HeaderMenu) => {
    const aItemIndex = headerMenuOrder
      .findIndex(headerItem => headerItem.grouping.toLocaleLowerCase() === a.label.toLowerCase())
    const bItemIndex = headerMenuOrder
      .findIndex(headerItem => headerItem.grouping.toLocaleLowerCase() === b.label.toLowerCase())
    return ((aItemIndex || 0) + 1) - ((bItemIndex || 0) + 1)
  })
  return sortedMenu
}

const sortHeaderItems = (menu: HeaderMenu[]) => {
  for (const item of menu) {
    for (const subMenu of item.subMenu) {
      if (subMenu.navigations.length > 1) {
        subMenu.navigations = sortHeaderNavigations(subMenu.navigations)
      }
    }
    if (item.subMenu.length > 1) {
      const subGroupingOrder = headerMenuOrder.find(itemOrder =>
        itemOrder.grouping.toLowerCase() === item.label.toLowerCase())?.subGrouping || []
      item.subMenu = sortHeaderSubMenu(item.subMenu, subGroupingOrder)
    }
  }

  if (menu.length > 1) {
    menu = sortHeaderMenu(menu)
  }
  return menu
}

const isViewAllProducts = (grouping: string | null) => grouping !== 'Help'

const addItemToSubMenu = (subMenu: any, item: NavigationResponse) => {
  let subMenuData = subMenu.find((subMenuItem: HeaderSubMenu) =>
    (subMenuItem.groupName === item.subGrouping) && item.subGrouping !== null)
  if (!subMenuData) {
    subMenuData = {}
    subMenuData.groupName = (item.subGrouping === 'null' || item.subGrouping === null) ? '' : item.subGrouping
    subMenuData.navigations = []
    subMenu.push(subMenuData)
  }
  const navItem = {
    label: item.linkLabel,
    link: (formatUrl.methods.parseLink(item.linkPath) || item.linkPath).replace('~', '-'),
    externalLink: item.linkPath ? !formatUrl.methods.parseLink(item.linkPath) : null,
  }
  subMenuData.navigations.push(navItem)
  productRanges.push(item.linkLabel)
}

const addItemToMenu = (menu: any, item: NavigationResponse) => {
  let menuData = menu.find((menuItem: HeaderMenu) => (menuItem.label === item.grouping) && item.grouping !== null)
  if (!menuData) {
    menuData = {}
    menuData.label = (item.grouping === 'null' || item.grouping === null) ? '' : item.grouping
    menuData.subMenu = []
    menuData.active = false
    menuData.viewAllProducts = isViewAllProducts(item.grouping)
    menu.push(menuData)
  }
  addItemToSubMenu(menuData.subMenu, item)
}

export default async({ app, store }: any) => {
  const client = app.apolloProvider.defaultClient
  let navigations: Array<HeaderMenu> = []
  try {
    const navHeaderResponse = await client.query({ query: getHeaderNavigations })
    const navHeaderResponseItems = [...navHeaderResponse.data.edsHeaderNavigationCollection.items]

    for (const item of navHeaderResponseItems) {
      addItemToMenu(navigations, item)
    }
    navigations = sortHeaderItems(navigations)
  } catch (response: any) {
    // eslint-disable-next-line no-console
    console.log('There was an error call from navigations collection.', response)
  }

  store.dispatch('list/setNavigations', navigations)
  store.dispatch('list/setProductRanges', productRanges)
  productRanges.forEach((productRange: string) => {
    store.dispatch('localisation/setContentItem', productRange)
  })
}