import getEbookObjectKeys from '~/graphql/queries/data/get-ebook-object-keys.gql'
import EbookObjectKey from '~/interfaces/ebook-object-key'

export default async function(
  app: any
) {
  try {
    const client = app.apolloProvider.defaultClient
    const response = await client.query({ query: getEbookObjectKeys })
    const ebookObjectKeys: Array<EbookObjectKey> = response.data.edsDownloadObjectKeysCollection.items
    return ebookObjectKeys
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log('There was an error in the fetching ebook download keys', error)
  }
}