var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header-desktop"},[_c('div',{staticClass:"header-desktop-content"},[_c('span',{staticClass:"flex items-center"},[_c('HeaderLogo')],1),_vm._v(" "),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({ fn: _vm.closeMenu }),expression:"{ fn: closeMenu }"}],staticClass:"flex justify-center"},_vm._l((_vm.menu),function(item,index){return _c('div',{key:index},[(item.link && item.externalLink && item.subMenu.length < 1)?[_c('a',{staticClass:"nav-item",class:{'nav-item-active': item.active},attrs:{"href":item.link,"tabindex":"0"},on:{"click":function($event){return _vm.toggleMenu(index)}}},[_c('span',[_vm._v("\n              "+_vm._s(_vm.$t(item.label))+"\n              "),_c('img',{staticClass:"dropdown-icon",attrs:{"height":"12","width":"12","alt":"menu dropdown"}})])])]:(item.link && !item.externalLink && item.subMenu.length < 1)?[_c('NuxtLink',{staticClass:"nav-item",class:{'nav-item-active': item.active},attrs:{"to":item.link,"tabindex":"0"},on:{"click":function($event){return _vm.toggleMenu(index)}}},[_c('span',[_vm._v("\n              "+_vm._s(_vm.$t(item.label))+"\n              "),_c('img',{staticClass:"dropdown-icon",attrs:{"alt":"menu dropdown"}})])])]:[_c('button',{staticClass:"nav-item",class:{'nav-item-active': item.active},attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.toggleMenu(index)}}},[_c('span',[_vm._v("\n              "+_vm._s(_vm.$t(item.label))+"\n              "),_c('img',{staticClass:"dropdown-icon",attrs:{"alt":"menu dropdown"}})])])],_vm._v(" "),(item.subMenu && item.subMenu.length > 0)?_c('div',{staticClass:"nav-dropdown",style:(_vm.enableBanner ? `top: ${parseInt(_vm.calculatedTopValue) + 92}px;` : '')},[_c('div',{staticClass:"nav-dropdown-wrapper"},[_c('div',{staticClass:"grid gap-x-2 mb-[26px]",class:{
                'grid-cols-1': item.subMenu.length === 1,
                'grid-cols-2': item.subMenu.length >= 2
              }},[_vm._l((item.subMenu),function(subMenu,subMenuIndex){return [_c('ul',{key:subMenu.groupName,staticClass:"ul-list",class:{
                    'grid-rows-1': _vm.getGridRowsLength(subMenu) === 1,
                    'grid-rows-2': _vm.getGridRowsLength(subMenu) === 2,
                    'grid-rows-3': _vm.getGridRowsLength(subMenu) === 3,
                    'grid-rows-4': _vm.getGridRowsLength(subMenu) === 4,
                    'grid-rows-5': _vm.getGridRowsLength(subMenu) === 5,
                    'grid-rows-6': _vm.getGridRowsLength(subMenu) > 5,
                    'max-w-[472px]': _vm.getGridRowsLength(subMenu) < 3,
                    'max-w-[952px]': _vm.getGridRowsLength(subMenu) >= 6,
                    'gap-x-2 grid-rows-min-content-6': item.subMenu.length === 1,
                  }},[(subMenu.groupName)?_c('li',{key:'subMenuGroupName-' + subMenuIndex,staticClass:"pt-4"},[_c('h2',{staticClass:"submenu-group-name"},[_vm._v("\n                      "+_vm._s(_vm.$t(subMenu.groupName))+"\n                    ")])]):_vm._e(),_vm._v(" "),_vm._l((subMenu.navigations),function(subMenuNav,subMenuNavIndex){return _c('li',{key:'subMenuNav-' + subMenuNavIndex + '-' + subMenuIndex,staticClass:"pt-4"},[(subMenuNav.externalLink)?[_c('a',{attrs:{"href":_vm.sanitizeUrl(subMenuNav.link)},on:{"click":function($event){$event.stopPropagation();return _vm.$_handleLinkClick.apply(null, arguments)}}},[_vm._v("\n                        "+_vm._s(_vm.$t(subMenuNav.label))+"\n                      ")])]:[_c('NuxtLink',{attrs:{"to":subMenuNav.link + '?nav_source=' + item.label},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_vm._v("\n                        "+_vm._s(_vm.$t(subMenuNav.label))+"\n                      ")])]],2)})],2)]})],2),_vm._v(" "),(item.viewAllProducts)?[_c('div',{staticClass:"h-[2px] mt-[2px] mb-[10px] bg-[#D9D9D9] mx-4"}),_vm._v(" "),_c('NuxtLink',{staticClass:"font-semibold mx-4",attrs:{"to":'/products?nav_source=' + item.label},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_vm._v("\n                "+_vm._s(_vm.$t('View all products'))+"\n              ")])]:_vm._e()],2),_vm._v(" "),_c('button',{staticClass:"btn-close",on:{"click":_vm.closeMenu}},[_c('img',{attrs:{"alt":"close nav dropdown","width":"24","height":"12"}})])]):_vm._e()],2)}),0),_vm._v(" "),_c('div',{staticClass:"flex justify-end items-center h-[90px]"},[_c('HeaderSearchInput'),_vm._v(" "),_c('HeaderMenuLocation'),_vm._v(" "),_c('HeaderBasket'),_vm._v(" "),_c('HeaderMenuAccount',{attrs:{"is-mobile":false}})],1)]),_vm._v(" "),_c('div',{staticClass:"border-line"})])
}
var staticRenderFns = []

export { render, staticRenderFns }