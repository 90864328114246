import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=d8db9982&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"
import style0 from "./Header.vue?vue&type=style&index=0&id=d8db9982&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderSkipNavLinks: require('/home/runner/work/english-digital-shop-frontend/english-digital-shop-frontend/components/Header/HeaderSkipNavLinks.vue').default,HeaderDesktop: require('/home/runner/work/english-digital-shop-frontend/english-digital-shop-frontend/components/Header/HeaderDesktop.vue').default,HeaderMobile: require('/home/runner/work/english-digital-shop-frontend/english-digital-shop-frontend/components/Header/HeaderMobile.vue').default,PopupNavigationMobile: require('/home/runner/work/english-digital-shop-frontend/english-digital-shop-frontend/components/Popup/PopupNavigationMobile.vue').default})
