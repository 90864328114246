
  import { Vue } from 'nuxt-property-decorator'
  import { EDSCookie } from '~/constants/cookie'

  export default Vue.extend({
    props: {
      isFixed: {
        type: Boolean,
        required: true
      },
      isHidden: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        isTruncated: true
      }
    },
    computed: {
      enableBanner(): boolean {
        return this.$store.state.settings.maintenance.enabled
      },
      maintenanceMessaging(): string {
        const messaging = this.$store.state.settings.maintenance.messaging
        if (!messaging || typeof messaging !== 'string') { return '' }
        return messaging
      },
      bannerMessaging(): string {
        return this.isTruncated ? this.maintenanceMessaging.slice(0, 100) + '...' : this.maintenanceMessaging
      },
      enableEcommerce(): boolean {
        return this.$store.state.settings.ecommerce.enabled
      },
    },
    created() {
      this.isTruncated = this.maintenanceMessaging.length > 100
    },
    methods: {
      hideBanner(): void {
        this.$store.dispatch('settings/setMaintenanceSettings', {
          enabled: false,
          messaging: this.bannerMessaging
        })

        this.setCookie()
      },

      setCookie(): void {
        this.$cookies.set(
          EDSCookie.MaintenanceBanner,
          this.enableBanner,
          {
            secure: true,
            path: '/',
            maxAge: this.$config.maintenanceBannerMaxAge
          }
        )
      },

      showMore(): void {
        this.isTruncated = false
        this.$emit('showMoreOpen', true)
      },
    }
  })
