import getSettings from '~/graphql/queries/data/get-settings.gql'

export default async({ app, store }: any) => {
  try {
    const client = app.apolloProvider.defaultClient
    const response = await client.query({
      query: getSettings,
      variables: { settingName: 'EDS Ecommerce Setting' }
    })
    store.dispatch('settings/setEcommerceSettings', {
      enabled: response.data.edsSettingsCollection.items[0].toggleSetting,
      messaging: response.data.edsSettingsCollection.items[0].messagingText
    })
  } catch {
    store.dispatch('settings/setEcommerceSettings', {
      enabled: true,
      messaging: ''
    })
  }
}