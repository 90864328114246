
  import { Vue } from 'nuxt-property-decorator'
  import mixinGetMaintenanceBehaviors from '~/mixins/getMaintenanceBehaviors'

  export default Vue.extend({
    mixins: [mixinGetMaintenanceBehaviors],
    data() {
      return {
        calculatedTopValue: this.getMaintenanceBannerHeight(this.$store.state.settings.maintenance.enabled)
      }
    },
    beforeMount() {
      window.addEventListener('scroll', this.closeDropdown)
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.closeDropdown)
    },
    methods: {
      closeDropdown() {
        this.$emit('closeDropdown')
      },
    }
  })
