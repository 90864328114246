import {
  validateTempCustomerId, getSsoToken, updateBasketItems, navigateToBasket, getBasketItems
} from './basket-common-service'
import { NewRelicService } from './new-relic-service'
import ProductCardData from '~/interfaces/product-card-data'
import Product from '~/interfaces/product'
import BasketProps from '~/interfaces/basket-props'
import { NRAction } from '~/constants/newrelic-action'

export default async function(
  axios: any,
  basketProps: BasketProps,
  $store: any,
  $cookies: any,
  product: Product | ProductCardData,
  newRelic: NewRelicService
) {
  try {
    const headers = {
      common: {
        'x-api-key': basketProps.apiKey
      }
    }
    const loginToken = $store.state.user?.token || null
    const loginUser = $store.state.user?.user || null
    if (loginToken && loginUser) {
      axios.setToken(loginToken, 'Bearer')
    }
    const storeId = basketProps.storeId
    const customerId: string = await validateTempCustomerId(
      axios, basketProps, headers, $store.state.customer.customerId
    )
    $store.dispatch('customer/setCustomerId', customerId)

    await updateBasketItems(
      axios, basketProps, headers, customerId, storeId, product.sapMaterialNumber
    )
    const basket = await getBasketItems(axios, basketProps, headers, customerId, storeId) as unknown as any[]
    $store.dispatch('list/emptyBasket')
    let productId = null
    for (const item of basket) {
      if (item.product?.sku === product.sapMaterialNumber) {
        productId = item.product.id
      }
      $store.dispatch('list/addToBasket', {
        sapMaterialNumber: item.product.sku,
        quantity: item.quantity
      })
    }
    const ssoToken = await getSsoToken(axios, basketProps, headers, customerId, storeId)
    const url = basketProps.checkoutUrl.replaceAll('basket', 'billing-details')
    await navigateToBasket(url, $store, $cookies, ssoToken, { productId })
    newRelic.interActionText(NRAction.BuyNowSuccess)
  } catch (response: any) {
    newRelic.interActionText(NRAction.BuyNowError)
    // eslint-disable-next-line no-console
    console.log(response.message, 'There was an error in the POST checkout API call.')
  }
}