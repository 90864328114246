
  import { Vue } from 'nuxt-property-decorator'
  import mixinHandleLinkClick from '~/mixins/handleLinkClick'

  export default Vue.extend({
    mixins: [mixinHandleLinkClick],
    computed: {
      showAddToBasketErrorPopup(): boolean {
        return this.$store.state.showAddToBasketErrorPopup
      }
    },
    methods: {
      closePopup(): void {
        this.$store.dispatch('setShowAddToBasketErrorPopup', false)
      },
      retryAddToBasket(): void {
        this.$store.dispatch('setIsRetryAddingToBasket', true)
        this.closePopup()
      },
    },
  })
