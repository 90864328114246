
  import { Vue } from 'nuxt-property-decorator'

  export default Vue.extend({
    methods: {
      closeDropdown() {
        this.$emit('closeDropdown')
      },
    }
  })
