import { Vue } from 'nuxt-property-decorator'
import { NRAction } from '~/constants/newrelic-action'
import GigyaValidationErrors from '~/interfaces/gigya-validation-errors'
import mixinGigyaScreensetTranslate from '~/mixins/gigyaScreensetTranslate'
import mixinFormatText from '~/mixins/formatText'
import { completeYourProfileTranslations } from '~/configs/screensets/complete-your-profile-translations'

export default Vue.extend({
  mixins: [mixinGigyaScreensetTranslate, mixinFormatText],
  methods: {
    addPasswordTogglers(parentId: string = ''): void {
      document.querySelectorAll(`${parentId} .gigya-composite-control-password`).forEach((element: Element) => {
        const txtPassword = element.querySelector('input')
        const txtPasswordToggler = document.createElement('button')
        const imageIcon = document.createElement('img')

        txtPasswordToggler.classList.add('password-visibility-toggler')
        txtPasswordToggler.type = 'button'
        txtPasswordToggler.appendChild(imageIcon)

        element.classList.add('input-type-password')

        txtPasswordToggler.addEventListener('click', function() {
          const inputType = element.querySelector('input')!.type
          txtPassword!.type = (inputType === 'password') ? 'text' : 'password'

          if (inputType === 'password') {
            element.classList.add('input-type-text')
            element.classList.remove('input-type-password')
          } else {
            element.classList.add('input-type-password')
            element.classList.remove('input-type-text')
          }

          txtPassword!.focus()
        })

        element.appendChild(txtPasswordToggler)
      })
    },
    addCountriesDropdownListeners(dropdownSelector: string): void {
      // Create custom dropdown elements
      const countriesDropdownGigya = document.querySelector(dropdownSelector) as HTMLSelectElement
      // adding a null check here as this code gets crash
      // when countriesDropdownGigya is null that result in inconsistent behavior.
      if (!countriesDropdownGigya) { return }
      countriesDropdownGigya.insertAdjacentHTML(
        'beforebegin',
        '<input type="text" id="registration-countries-input" tabindex="0"></input>'
      )
      const countriesTextInput = document.getElementById('registration-countries-input') as HTMLInputElement
      countriesTextInput.insertAdjacentHTML(
        'afterend',
        '<div id="countries-dropdown-display" class="registration-countries-dropdown"></div>'
      )
      const countriesDropdownGigyaOptions = countriesDropdownGigya.querySelectorAll('option')
      const countriesDropdown = document.getElementById('countries-dropdown-display') as HTMLElement
      countriesDropdownGigyaOptions.forEach((option) => {
        if (option.value) {
          const item = document.createElement('li')
          item.setAttribute('data-value', option.value)
          item.setAttribute('tabindex', '0')
          item.innerText = option.innerText
          countriesDropdown.appendChild(item)
        }
      })
      let countriesErrorMessage = countriesTextInput.parentElement?.querySelector('.gigya-error-msg') as HTMLElement

      // Overrides for Complete Your Profile Popup Form
      const completeRegistrationForm = document.querySelector('#gigya-complete-registration-screen') as HTMLElement
      if (completeRegistrationForm) {
        const btnSubmitRegistration = completeRegistrationForm.querySelector('.gigya-input-submit') as HTMLButtonElement
        const countriesDropdownContainer = completeRegistrationForm.querySelector('.countries-dropdown') as HTMLElement
        countriesErrorMessage = countriesDropdownContainer.querySelector('.gigya-error-msg') as HTMLElement
        if (btnSubmitRegistration && countriesDropdownContainer) {
          btnSubmitRegistration.onclick = (event: any) => {
            countriesDropdownGigyaUpdate()
            if (!countriesDropdownContainer.classList.contains('is-valid')) {
              event.preventDefault()
              countriesErrorMessage.innerHTML = this.$t('Please enter a country')
              countriesErrorMessage.classList.add('gigya-error-msg-active')
            } else {
              countriesErrorMessage.innerHTML = ''
              countriesErrorMessage.classList.remove('gigya-error-msg-active')
            }
          }
        }
      }

      // Listeners and handlers
      const countriesTextInputFocusListener = {
        handleEvent: () => {
          countriesDropdown?.classList.add('visible')
          const countriesDropdownItems = countriesDropdown?.querySelectorAll('li')
          countriesDropdownItems?.forEach((item, index) => {
            item.addEventListener('mousedown', countriesItemMousedownListener, false)
            item.addEventListener('keydown', countriesItemKeydownListener, false)
            item.addEventListener('focusout', countriesItemFocusOutListener, false)
            validateCountryItems(countriesTextInput.value, item, index, countriesDropdownItems)
          })
        }
      }
      const countriesTextInputFocusOutListener = {
        handleEvent: (event:FocusEvent) => {
          if ((event.relatedTarget as HTMLElement)?.tagName.toLowerCase() !== 'li') {
            closeCountriesDropdown()
          }
        }
      }
      const countriesTextInputKeydownListener = {
        handleEvent: (event: KeyboardEvent) => {
          const key = event.key

          if (key === 'Enter') {
            event.preventDefault()
            closeCountriesDropdown()
            return false
          }

          if (key === 'ArrowDown') {
            event.preventDefault()
            const item = countriesDropdown.querySelector('li:not(.hide)') as HTMLElement
            item.focus()
          }

          const textInput = countriesTextInput?.value
          if (textInput === '') {
            countriesDropdownGigya.selectedIndex = 0
          }
          const countriesDropdownItems = countriesDropdown?.querySelectorAll('li')
          countriesDropdownItems?.forEach((country, index) => {
            validateCountryItems(textInput, country, index, countriesDropdownItems)
          })
        }
      }
      const countriesTextInputKeyupListener = {
        handleEvent: () => {
          const textInput = countriesTextInput?.value
          if (textInput === '') {
            countriesDropdownGigya.selectedIndex = 0
          }
          const countriesDropdownItems = countriesDropdown?.querySelectorAll('li')
          countriesDropdownItems?.forEach((country, index) => {
            validateCountryItems(textInput, country, index, countriesDropdownItems)
          })
          completeRegistrationFormErrorUpdate()
        }
      }
      const countriesItemMousedownListener = {
        handleEvent: (event: Event) => {
          const item = event.currentTarget as HTMLElement
          countriesTextInput.value = item.innerText
          closeCountriesDropdown()
          countriesDropdownGigyaUpdate()
        }
      }
      const countriesItemKeydownListener = {
        handleEvent: (event: KeyboardEvent) => {
          const key = event.key

          switch (key) {
          case 'ArrowDown': {
            event.preventDefault()
            let nextElement = document.activeElement?.nextElementSibling as HTMLElement
            while (nextElement.classList.contains('hide')) {
              nextElement = nextElement.nextElementSibling as HTMLElement
            }
            if (nextElement) {
              nextElement.focus()
            }
            break
          }
          case 'ArrowUp': {
            event.preventDefault()
            let previousElement = document.activeElement?.previousElementSibling as HTMLInputElement
            while (previousElement && previousElement.classList.contains('hide')) {
              previousElement = previousElement.previousElementSibling as HTMLInputElement
            }
            if (previousElement) {
              previousElement.focus()
            } else {
              countriesTextInput.focus()
            }
            break
          }
          case 'Enter': {
            countriesTextInputUpdate((document.activeElement as HTMLElement)?.innerText)
            closeCountriesDropdown()
            countriesDropdownGigyaUpdate()
            break
          }
          case 'Escape': {
            closeCountriesDropdown()
            countriesDropdownGigyaUpdate()
            break
          }
          }
        }
      }
      const countriesItemFocusOutListener = {
        handleEvent: (event:FocusEvent) => {
          if ((event.relatedTarget as HTMLElement)?.tagName.toLowerCase() !== 'li') {
            closeCountriesDropdown()
          }
        }
      }

      // Helpers and effects
      const completeRegistrationFormErrorUpdate = () => {
        if (completeRegistrationForm) {
          const countriesDropdownContainer = completeRegistrationForm.querySelector(
            '.countries-dropdown'
          ) as HTMLElement
          if (!countriesDropdownContainer.classList.contains('is-valid')) {
            countriesErrorMessage.innerHTML = this.$t('Please enter a country')
            countriesErrorMessage.classList.add('gigya-error-msg-active')
          } else {
            countriesErrorMessage.innerHTML = ''
            countriesErrorMessage.classList.remove('gigya-error-msg-active')
          }
        }
      }
      const countriesTextInputUpdate = (text: string) => {
        countriesTextInput.value = text
      }
      const closeCountriesDropdown = () => {
        countriesDropdown?.classList.remove('visible')
        countriesDropdownGigyaUpdate()
        completeRegistrationFormErrorUpdate()
        countriesDropdown?.querySelectorAll('li').forEach((item) => {
          item.removeEventListener('mousedown', countriesItemMousedownListener)
        })
      }
      const countriesDropdownGigyaUpdate = () => {
        let foundIndex = 0
        countriesDropdownGigyaOptions.forEach((gigyaOption: HTMLOptionElement, index: number) => {
          if (gigyaOption.innerText === countriesTextInput?.value) {
            foundIndex = index
          }
        })
        countriesDropdownGigya.selectedIndex = foundIndex
        if (foundIndex > 0) {
          countriesTextInput.parentElement?.classList.add('is-valid')
          countriesTextInput.parentElement?.classList.remove('has-error')
          countriesErrorMessage.classList.remove('gigya-error-msg-active')
          countriesDropdownGigya.classList.remove('gigya-error')
          countriesDropdownGigya.classList.add('gigya-valid')
          countriesDropdownGigya.setAttribute('aria-invalid', 'false')
        } else if (countriesErrorMessage.classList.contains('gigya-error-msg-active')) {
          countriesTextInput.parentElement?.classList.remove('is-valid')
          countriesTextInput.parentElement?.classList.add('has-error')
        } else {
          countriesTextInput.parentElement?.classList.remove('is-valid')
        }
      }
      const lookupCountryISO = (country: HTMLElement): Array<string> => {
        const isoFromDataset = country.dataset?.value
        const isoFromStore = this.$store.getters['list/getIsoByCountryName'](country?.innerText ?? '')
        return Array.from(new Set(
          Object.values({...isoFromStore, isoFromDataset})
        ))
      }
      const evaluateCountryISO = (country: HTMLElement, search: string): boolean => {
        const isoList = lookupCountryISO(country)
        if (!isoList.length) { return false }
        return isoList.map((iso) => iso.toLocaleLowerCase()).includes(search)
      }
      const evaluateCountryName = (country: HTMLElement, search: string): boolean => {
        let normalizedCountry = country.innerText.toLowerCase()
        if (!this.containsDiacriticChar(search)) {
          normalizedCountry = this.normalizeDiacritic(normalizedCountry)
        }

        return normalizedCountry.includes(search)
      }
      const validateCountryItems = (
        textInput: string,
        country: HTMLElement,
        index: number,
        countriesDropdownItems: NodeListOf<HTMLElement>
      ) => {
        const normalizedTextInput = textInput.toLowerCase()
        const isoCondition = evaluateCountryISO(country, normalizedTextInput)
        const countryNameCondition = evaluateCountryName(country, normalizedTextInput)
        const hideCondition: Array<boolean> = [
          !!textInput,
          textInput.length >= 1,
          !countryNameCondition,
          !isoCondition
        ]

        if (hideCondition.every((bool) => bool)) {
          countriesDropdownItems[index].classList.add('hide')
        } else {
          countriesDropdownItems[index].classList.remove('hide')
        }
      }

      // Event bindings
      countriesTextInput?.addEventListener('focus', countriesTextInputFocusListener, false)
      countriesTextInput?.addEventListener('focusout', countriesTextInputFocusOutListener, false)
      countriesTextInput?.addEventListener('keydown', countriesTextInputKeydownListener, false)
      countriesTextInput?.addEventListener('keyup', countriesTextInputKeyupListener, false)
    },
    /**
     * Adds the `your country` field whenever the `complete your profile modal` is rendered.
     */
    addCountriesFieldOnModalRender(timeout = 10000) {
      let timer: NodeJS.Timeout
      const observer = new MutationObserver(() => {
        const query = `.gigya-screen-dialog .countries-dropdown select, 
        .gigya-screen-dialog-mobile .countries-dropdown select`
        const fieldID = 'registration-countries-input'
        // Add the `your country` if the gigya dropdown element is rendered. But, only do so
        // if the `your contry` field hasn't been added yet.
        if (document.querySelectorAll(query).length && !document.getElementById(fieldID)) {
          this.addCountriesDropdownListeners(query)
          this.translateDataKeys(completeYourProfileTranslations)
          // Disconnect the observer after a set amount of time that the modal isn't re-rendered.
          if (timer) { clearTimeout(timer) }
          timer = setTimeout(() => {
            observer.disconnect()
          }, timeout)
        }
      })
      observer.observe(document.body, { childList: true, subtree: true })
    },
    addInputErrorListeners(): void {
      const addErrorMutationListener = (element: Element) => {
        const errorSpan = element.querySelector('.gigya-error-msg')
        const handleErrorMutation = (mutationList: any) => {
          mutationList.forEach((mutation: any) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
              const inputElement = mutation.target.previousElementSibling

              if (mutation.target.classList.contains('gigya-error-msg-active')) {
                mutation.target.parentElement.classList.add('has-error')
                mutation.target.parentElement.classList.remove('no-error')

                if (inputElement.classList.contains('gigya-input-password')) {
                  if (document.querySelector('#gigya-register') || document.querySelector('#gigya-reset-password')) {
                    mutation.target.innerHTML = this.getPasswordErrorMessaging()
                  }
                }
              } else {
                mutation.target.parentElement.classList.remove('has-error')
                mutation.target.parentElement.classList.add('no-error')
                if (document.querySelector('#gigya-login')) {
                  return
                }
                if (inputElement.classList.contains('gigya-input-password')) {
                  if (this.validatePassword(inputElement.value) === false) {
                    this.showPasswordError(inputElement)
                  }
                }
              }
            }
          })
        }

        new MutationObserver(handleErrorMutation).observe(errorSpan!, { attributes: true })
      }
      const addInputMutationListener = (element: Element) => {
        const handleInputMutation = (mutationList: any) => {
          mutationList.forEach(function(mutation: any) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
              if (mutation.target.classList.contains('gigya-valid')) {
                mutation.target.parentElement.classList.add('is-valid')
              } else {
                mutation.target.parentElement.classList.remove('is-valid')
              }
            }
          })
        }

        new MutationObserver(handleInputMutation).observe(element, { attributes: true })
      }

      document.querySelectorAll('.gigya-composite-control-textbox').forEach((element) => {
        element.classList.add('no-error')
        addErrorMutationListener(element)
        addInputMutationListener(element.querySelector('input')!)
      })

      document.querySelectorAll('.gigya-composite-control-password').forEach((element) => {
        element.classList.add('no-error')
        addErrorMutationListener(element)
        addInputMutationListener(element.querySelector('input')!)
      })

      document.querySelectorAll('.gigya-composite-control-dropdown').forEach((element) => {
        element.classList.add('no-error')
        addErrorMutationListener(element)
        addInputMutationListener(element.querySelector('select')!)
      })
    },
    updateSignInBtnText() {
      const signInBtnSelector = `#gigya-login #gigya-login-form
      .gigya-layout-cell.with-site-login input.gigya-input-submit`
      const signInBtn: HTMLInputElement | null = document.querySelector(signInBtnSelector)

      if (signInBtn) {
        // replacing outerHTML as signInBtn.value is not working. Its being override by gigya frequently
        signInBtn.outerHTML = signInBtn.outerHTML.replace('Sign In', this.$t('Continue'))
        signInBtn.value = 'Continue'
      }
    },
    showHidePasswordField(display: boolean = false) {
      const siteLogin: HTMLElement | null = document.querySelector(`#gigya-login #gigya-login-form
        .gigya-layout-cell.with-site-login`)

      if (siteLogin) {
        siteLogin.classList.remove('hide-password-fields')

        if (display) {
          siteLogin.classList.add('hide-password-fields')
        }
      }

      const passwordLabel: HTMLElement | null = document.querySelector(`#gigya-login-form 
        .gigya-composite-control-label[data-translation-key="LABEL_139998946623832940_LABEL"]`)

      if (passwordLabel) {
        passwordLabel.innerHTML = this.$t('Password')
      }

      const socialLoginSelector = `#gigya-login #gigya-login-form .gigya-layout-row:first-child 
        .gigya-composite-control-social-login`
      const socialLogins: HTMLElement | null = document.querySelector(socialLoginSelector)

      if (socialLogins) {
        const socialLoginsParentNode = socialLogins.parentNode as HTMLElement
        if (!socialLoginsParentNode.classList.contains('with-social-login')) {
          const socialLoginsParent = document.querySelector(`#gigya-login #gigya-login-form
            .gigya-layout-cell.with-social-login`)

          if (socialLoginsParent) {
            socialLoginsParent.appendChild(socialLogins)
          }
        }
      }
    },
    updateUserEmail(email: string) {
      const userEmailEle: HTMLInputElement | null = document.querySelector(`#gigya-login #gigya-login-form
        input[name="username"]`)
      if (userEmailEle) {
        userEmailEle.value = email
      }
    },
    addRegisterSuccessMessage(className: string): void {
      const successText = this.$t('You\'ve successfully registered your account. Please sign in to continue.')
      const socialLoginDiv = document.querySelector(className) as HTMLSelectElement
      socialLoginDiv?.insertAdjacentHTML(
        'beforeend',
        `<div class="register-success-container">
          <img class="register-success-icon">
          </img>
          <p class="register-success-text">
            ${successText}
          </p>
        </div>`
      )
    },
    getPasswordErrorMessaging(): string {
      const warnings = [
        'Eight (8) characters or more',
        'One (1) letter or more',
        'One (1) number or more'
      ]

      return `
        ${this.$t('Your password must include')}
        <ul class="list-disc ml-6">
          ${warnings.map(msg => `<li>${this.$t(msg)}</li>`).join('')}
        </ul>
      `
    },
    addEventHandlerOnElements(
      elementList: string[], handler: EventListenerOrEventListenerObject, event: string): void {
      if (!elementList || elementList.length === 0) { return }

      setTimeout(() => {
        for (const element of elementList) {
          const ele: HTMLElement | null = document.querySelector(element)
          ele?.addEventListener(event, handler)
        }
      }, 0)
    },
    autoPopulateInputFields(autoPopulateFields: {value: string, selector: HTMLInputElement}[]): void {
      if (!autoPopulateFields || autoPopulateFields.length === 0) { return }

      for (const field of autoPopulateFields) {
        if (field.selector && field.value) {
          field.selector.value = field.value
        }
      }
    },
    getLocale(): string {
      return this.$store.state.localisation.locale ? this.$store.state.localisation.locale.substring(0, 2) : 'default'
    },
    getEmailErrorMessaging(email: string):string {
      let errorString = ''
      if (email === '') {
        errorString = `<span class="text-error-500">${this.$t('Please enter an email address')}</span>`
      } else {
        errorString = `<div class="eds-text-sm
          eds-leading-tight font-normal account-not-found-error">
          <div class="register-here-link text-error-500">
            ${this.$t('We cannot find your account.')} 
              <a class="gigya-composite-control-link link-register font-bold"
              id="register-link" href="/register">${this.$t('Register here')}</a>
          </div>
          <div class="text-neutral-500 complete-account-setup-link mt-2">
            ${this.$t('Already purchased something? You might need to')} 
              <a class="gigya-composite-control-link link-complete-account-setup
                font-bold" id="complete-account-setup-link"
                  href="/register">${this.$t('complete account set-up here')}</a>.
          </div>
        </div>`
      }
      return errorString
    },
    removeLastLoginIndicationImage(): void {
      document.querySelectorAll('#gigya-link-account-screen button').forEach((element) => {
        element.querySelector('img')!.remove()
        element.appendChild(document.createElement('div'))
      })
    },
    async saveLogin(event: any): Promise<void> {
      // calling updateSignInBtnText here as after successful sign in, this page gets rendered for fraction of time.
      // which required update of sign in button text again.
      this.$newRelic.interActionText(NRAction.SignIn)
      this.updateSignInBtnText()
      const user = event?.response?.user || event?.response?.userInfo

      if (user) {
        this.user = user
        await this.$store.dispatch('user/setUserData', this.user)

        const UID = user.UID

        this.addLocaleSocialPseudoElements()

        // @ts-ignore
        await gigya.accounts.getJWT({
          fields: 'firstName, lastName, email',
          UID,
          expiration: 86400,
          callback: async(response: any) => {
            const jwt = response.id_token

            if (jwt) {
              await this.$store.dispatch('user/setUserToken', jwt)
              await this.$basketMerge()
              this.redirectUser()
            }
          }
        })
      }
    },
    async saveLogout(): Promise<void> {
      await this.$store.dispatch('user/setUserData', null)
      await this.$store.dispatch('user/setUserToken', '')
      await this.$store.dispatch('user/setSessionId', '')
      await this.$store.dispatch('list/setOrderIds', [])
      await this.$store.dispatch('customer/setCustomerId', null)
    },
    showPasswordError(passwordInput: Element): void {
      passwordInput?.nextElementSibling?.classList.add('gigya-error-msg-active')
      passwordInput?.parentElement?.classList.add('has-error')
      passwordInput?.parentElement?.classList.remove('no-error')
      passwordInput?.classList.remove('gigya-show-checkmark')
      passwordInput?.classList.remove('gigya-valid')
    },

    validateGigyaFormFields(event: any, errors: GigyaValidationErrors | any = {}) {
      if (event.screen === 'gigya-register-screen') {
        if (event.formData['profile.firstName'] === '') {
          if (!errors.profile) {
            errors.profile = {}
          }
          errors.profile.firstName = this.$t('Please enter a first name')
        }

        if (event.formData['profile.lastName'] === '') {
          if (!errors.profile) {
            errors.profile = {}
          }
          errors.profile.lastName = this.$t('Please enter a last name')
        }

        if (event.formData.email === '') {
          errors.email = this.$t('Please enter an email address')
        }

        if (this.validatePassword(event.formData.password) === false) {
          errors.password = this.getPasswordErrorMessaging()
        }

        if (event.formData['profile.country'] === '') {
          if (!errors.profile) {
            errors.profile = {}
          }
          errors.profile.country = this.$t('Please enter a country')
        }
      }

      if (event.screen === 'gigya-reset-password-screen') {
        if (this.validatePassword(event.formData.newPassword) === false) {
          errors.newPassword = this.getPasswordErrorMessaging()
        }
      }

      if (event.screen === 'gigya-login-screen') {
        this.translateDataKeys([
          {
            key: '.gigya-error-code-400027',
            delay: 120
          }
        ])
      }

      return errors
    },
    validatePassword(password: string): boolean {
      return password !== '' &&
        password.length >= 8 &&
        (/[a-zA-Z]+/.test(password) && /[0-9]+/.test(password)) === true
    },
    validEmailAddress(element: HTMLInputElement): boolean {
      return element?.value !== '' && element?.classList.contains('gigya-valid')
    },
    async redirectUser() {
      if (this.$store.state.returnURL) {
        const returnURL = this.$store.state.returnURL
        await this.$store.dispatch('setReturnURL', null)
        this.$router.push('/' + returnURL)
      } else {
        this.$router.push('/home')
      }
    },
    isUserAccountExists(userEmail: string): Promise<boolean> {
      return new Promise((resolve) => {
        // @ts-ignore
        gigya.accounts.auth.getMethods({
          apiKey: process.env.GIGYA_API_KEY,
          identifier: userEmail,
          callback: (res: any) => {
            // if errorCode is 0, then account exists
            if (res.errorCode !== null && res.errorCode === 0) {
              resolve(true)
            } else {
              resolve(false)
            }
          }
        })
      })
    }
  }
})