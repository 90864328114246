export const state = () => ({
  adCampaignParams: [],
  countries: [],
  basket: [],
  products: [],
  categories: [],
  filters: [],
  navigations: [],
  tooltips: [],
  footerLinks: [],
  orderIds: [],
  languages: [],
  npsPopupSettings: {},
  productRanges: [],
  exchangeRates: []
});

export const mutations = {
  ADD_TO_BASKET(state, product) {
    const index = state.basket.findIndex(item => item.sapMaterialNumber === product.sapMaterialNumber);
    if (index > -1) {
      state.basket[index] = product;
    } else {
      state.basket.push(product);
    }
  },
  EMPTY_BASKET(state) {
    state.basket = [];
  },
  SET_AD_CAMPAIGN_PARAMS(state, adCampaignParams) {
    state.adCampaignParams = adCampaignParams;
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
  SET_NAVIGATIONS(state, navigations) {
    state.navigations = navigations;
  },
  SET_TOOLTIPS(state, tooltips) {
    state.tooltips = tooltips;
  },
  SET_FOOTER_LINKS(state, footerLinks) {
    state.footerLinks = footerLinks;
  },
  SET_ORDER_IDS(state, orderIds) {
    state.orderIds = orderIds;
  },
  SET_LANGUAGES(state, languages) {
    state.languages = languages;
  },
  SET_NPS_POPUP_SETTINGS(state, npsPopupSettings) {
    state.npsPopupSettings = npsPopupSettings;
  },
  SET_PRODUCT_RANGES(state, productRanges) {
    state.productRanges = productRanges;
  },
  SET_EXCHANGE_RATES(state, exchangeRates) {
    state.exchangeRates = exchangeRates;
  }
};

export const actions = {
  addToBasket({ commit }, product) {
    commit('ADD_TO_BASKET', product);
  },
  emptyBasket({ commit }) {
    commit('EMPTY_BASKET');
  },
  setAdCampaignParams({ commit }, adCampaignParams) {
    commit('SET_AD_CAMPAIGN_PARAMS', adCampaignParams);
  },
  setCountries({ commit }, countries) {
    commit('SET_COUNTRIES', countries);
  },
  setCategories({ commit }, categories) {
    commit('SET_CATEGORIES', categories);
  },
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  setNavigations({ commit }, navigations) {
    commit('SET_NAVIGATIONS', navigations);
  },
  setTooltips({ commit }, tooltips) {
    commit('SET_TOOLTIPS', tooltips);
  },
  setFooterLinks({ commit }, footerLinks) {
    commit('SET_FOOTER_LINKS', footerLinks);
  },
  setOrderIds({ commit }, orderIds) {
    commit('SET_ORDER_IDS', orderIds);
  },
  setLanguages({ commit }, languages) {
    commit('SET_LANGUAGES', languages);
  },
  setNpsPopupSettings({ commit }, npsPopupSettings) {
    commit('SET_NPS_POPUP_SETTINGS', npsPopupSettings);
  },
  setProductRanges({ commit }, productRanges) {
    commit('SET_PRODUCT_RANGES', productRanges);
  },
  setExchangeRates({ commit }, exchangeRates) {
    commit('SET_EXCHANGE_RATES', exchangeRates);
  }
};

export const getters = {
  getIsoByCountryName: state => (countryName) => {
    const iso = {}
    const country = state.countries.find((country) => {
      return country?.countryName === countryName
    })

    if (!country) { return {} }

    for (const isoKey of ['countryCodeIso2', 'countryCodeIso3']) {
      if (isoKey in country && country[isoKey]) {
        iso[isoKey] = country[isoKey]
      }
    }
    
    return iso
  }
};