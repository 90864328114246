
  import { Vue } from 'nuxt-property-decorator'
  import HeaderSkipNavLinks from '~/components/Header/HeaderSkipNavLinks.vue'
  import HeaderDesktop from '~/components/Header/HeaderDesktop.vue'
  import HeaderMobile from '~/components/Header/HeaderMobile.vue'
  import PopupNavigationMobile from '~/components/Popup/PopupNavigationMobile.vue'
  import HeaderMenu from '~/interfaces/header-menu'
  import mixinGetMaintenanceBehaviors from '~/mixins/getMaintenanceBehaviors'

  export default Vue.extend({
    components: {
      HeaderSkipNavLinks,
      HeaderDesktop,
      HeaderMobile,
      PopupNavigationMobile
    },
    mixins: [mixinGetMaintenanceBehaviors],
    props: {
      isFixed: {
        type: Boolean,
        required: true
      },
      isHidden: {
        type: Boolean,
        required: true
      },
      showMore: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        menu: this.$cloneNestedArray(this.$store.state.list.navigations) as Array<HeaderMenu>,
        isMenuOpen: false,
        isMobileNavOpen: false,
        isInMobileOrTablet: false,
        calculatedTopValue: '0px',
      }
    },
    computed: {
      enableBanner(): boolean {
        return this.$store.state.settings.maintenance.enabled
      }
    },
    watch: {
      enableBanner(isTrue: boolean) {
        this.updateCalculatedTopValue(isTrue)
      },
      showMore() {
        this.updateCalculatedTopValue(true)
      }
    },
    mounted() {
      this.checkIfInMobileOrTablet()
      this.updateCalculatedTopValue(this.enableBanner)
    },
    beforeMount() {
      window.addEventListener('resize', this.checkIfInMobileOrTablet)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkIfInMobileOrTablet)
    },
    methods: {
      setMenuFlag(value: boolean) {
        this.isMenuOpen = value
        this.$emit('setMenuFlag', value)
      },
      checkIfInMobileOrTablet() {
        this.isInMobileOrTablet = window.innerWidth < 1280
        this.updateCalculatedTopValue(this.enableBanner)
      },
      toggleMobileNav() {
        this.isMobileNavOpen = !this.isMobileNavOpen
        this.$store.dispatch('setIsPopupOpen', this.isMobileNavOpen)
      },
      updateCalculatedTopValue(useBannerHeight: boolean) {
        if (useBannerHeight) {
          this.calculatedTopValue = this.getMaintenanceBannerHeight(this.enableBanner)
        } else {
          this.calculatedTopValue = '0px'
        }
      }
    }
  })
