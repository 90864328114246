import { render, staticRenderFns } from "./PopupMenuLocationBase.vue?vue&type=template&id=3d15617b&scoped=true&"
import script from "./PopupMenuLocationBase.vue?vue&type=script&lang=ts&"
export * from "./PopupMenuLocationBase.vue?vue&type=script&lang=ts&"
import style0 from "./PopupMenuLocationBase.vue?vue&type=style&index=0&id=3d15617b&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d15617b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputCountrySearch: require('/home/runner/work/english-digital-shop-frontend/english-digital-shop-frontend/components/Input/InputCountrySearch.vue').default,InputLanguageSearch: require('/home/runner/work/english-digital-shop-frontend/english-digital-shop-frontend/components/Input/InputLanguageSearch.vue').default})
