import { Vue } from 'nuxt-property-decorator'
import { screensetCustomLang } from '~/configs/screensets/gigya-custom-lang'
import { TranslateDataKeyOptions } from '~/interfaces/translate-data-key-options'

export default Vue.extend({
  methods: {
    customLangTranslations(): Record<string, string> {
      const initCustomLang: Record<string, string> = {}
      for (const lang in screensetCustomLang) {
        initCustomLang[lang] = this.$t(screensetCustomLang[lang])
      }
      return initCustomLang
    },
    translateDataKeys(translateOptions: Array<TranslateDataKeyOptions>) {
      for (const options of translateOptions) {
        const key = options.key
        const selectorFn = options?.selectorFn
        const root = options?.root ?? '.main-content '

        const timedOut = setTimeout(() => {
          const elements = document.querySelectorAll<any>(
            `${root}${selectorFn ? selectorFn(key) : key}`
          )
          if (elements.length) {
            for (const element of Array.from(elements)) {
              const attr = options?.attr ?? 'innerHTML'
              if (attr in element) {
                element[attr] = this.$t(element[attr])
              }
            }
          }
          clearTimeout(timedOut)
        }, options?.delay ?? 0)
      }
    },
    translateLoginSocials(targetSelector: string, timeout = 100): void {
      const target = document.querySelector(targetSelector)
      if (!target) { return }
      const observer = new MutationObserver(() => {
        const element = document.querySelectorAll('.gigya-login-providers-list')
        if (element.length) {
          this.addLocaleSocialPseudoElements(timeout)
          setTimeout(() => {
            observer.disconnect()
          }, timeout)
        }
      })

      observer.observe(
        target,
        { childList: true, subtree: true }
      )
    },
    addLocaleSocialPseudoElements(timeout = 100) {
      const locale = this.getLocale()
      if (locale === 'en' || locale === 'default' || !locale) { return }
      const socials = [
        'Facebook',
        'Google',
        'Apple',
        'Microsoft',
      ]
      setTimeout(() => {
        for (const social of socials) {
          const elements = document.querySelectorAll(`#${social}_btn div`)
          if (elements.length) {
            for (const element of Array.from(elements)) {
              element?.classList.add(locale)
            }
          }
        }
      }, timeout)
    }
  }
})