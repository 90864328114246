import Vue from 'vue'
import moment, { unix } from 'moment'
export default (_context: any, inject: any) => {
  Vue.filter('moment', (value: any, format: string = 'YYYY-MM-DD') => {
    if (typeof value === 'number') {
      return unix(value).format(format)
    }
    return moment(value).format(format)
  })
  inject('moment', moment)
}