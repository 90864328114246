
  import { Vue } from 'nuxt-property-decorator'

  import getProductMatches from '~/graphql/queries/data/get-product-matches.gql'
  import mixinGetMaintenanceBehaviors from '~/mixins/getMaintenanceBehaviors'

  const ARROW_DOWN_KEYCODE = 40
  const ARROW_UP_KEYCODE = 38
  const ENTER_KEYCODE = 13
  const TAB_KEYCODE = 9

  export default Vue.extend({
    mixins: [mixinGetMaintenanceBehaviors],
    props: {
      isInSearchResultPage: {
        type: Boolean
      },
      isInMobileOrTablet: {
        type: Boolean
      }
    },
    data() {
      return {
        isSearchModalOpen: false,
        keyword: (typeof this.$route !== 'undefined') ? this.$route.query.q : '',
        matches: [],
        isLoading: false,
        isInputFocused: false,
        calculatedTopValue: '0px'
      }
    },
    computed: {
      enableBanner(): boolean {
        return this.$store.state.settings.maintenance.enabled
      },
    },
    watch: {
      isSearchModalOpen(isTrue: boolean) {
        this.updateCalculatedTopValue(isTrue)
      },
      enableBanner(isTrue: boolean) {
        this.updateCalculatedTopValue(isTrue)
      }
    },
    methods: {
      selectProduct(index: number): void {
        this.$router.push('/product/' + index)
        this.togglePopup(false)
      },
      goToSearchResultPage(): void {
        if (this.keyword) {
          this.$router.push('/search?q=' + encodeURIComponent((this.keyword as string).trim() ))
        } else {
          this.$router.push('/search')
        }
        this.togglePopup(false)
      },
      async query() {
        const variables = {
          keyword: this.keyword,
          limit: 8,
          preview: this.$store.state.settings.preview
        }
        const results = await this.$apollo.query({ query: getProductMatches, variables })
        if (results.data.edsProductCollection.total) {
          this.matches = results.data.edsProductCollection.items.map((obj: any, index: any) => ({
            id: index,
            ...obj
          }))
        } else {
          this.matches = []
        }
      },
      clearKeyword(): void {
        this.keyword = ''
        this.matches = []
      },
      complete(): void {
        this.goToSearchResultPage()
      },
      onSelectedData(e: any): void {
        if (e.keyCode === ENTER_KEYCODE) {
          this.complete()
        }
      },
      async onInputChange(e: any) {
        const isEnterKey = e.keyCode === ENTER_KEYCODE
        const isArrowDownKey = e.keyCode === ARROW_DOWN_KEYCODE
        const isArrowUpKey = e.keyCode === ARROW_UP_KEYCODE
        const isTabKey = e.keyCode === TAB_KEYCODE

        if (isEnterKey || isArrowDownKey || isArrowUpKey || isTabKey) {
          return
        }

        this.keyword = e.target.value.toLowerCase()

        if (this.keyword.length > 1) {
          this.togglePopup(true)
          this.isLoading = true
          setTimeout(async() => {
            await this.query()
          }, 500);
          this.isLoading = false
        } else {
          this.isLoading = false
          this.matches = []
        }
      },
      togglePopup(newValue: boolean): void {
        this.isSearchModalOpen = newValue
        const input = this.$refs.input as HTMLElement

        if (newValue === true) {
          setTimeout(function() {
            input.focus()
          }, 500)
        }
      },
      back() {
        if (this.isSearchModalOpen) {
          this.isSearchModalOpen = false
        } else {
          this.$router.back()
        }
      },
      updateCalculatedTopValue(useBannerHeight: boolean) {
        if (useBannerHeight) {
          this.calculatedTopValue = this.getMaintenanceBannerHeight(this.enableBanner)
        } else {
          this.calculatedTopValue = '0px'
        }
      }
    }
  })
