
  import { Vue } from 'nuxt-property-decorator'

  export default Vue.extend({
    props: {
      isMobile: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        isMenuActive: false
      }
    },
    computed: {
      user() {
        return this.$store.state.user?.user || {}
      },
      gigyaEnabled() {
        return this.$config.gigyaEnabled
      }
    },
    watch: {
      isMenuActive(newVal) {
        return newVal
      }
    },
    methods: {
      toggleMobileNav() {
        this.$emit('toggleMobileNav')
      },
      toggleMenu() {
        this.isMenuActive = !this.isMenuActive
      },
      toggleMenuClose() {
        this.isMenuActive = false
      }
    }
  })
