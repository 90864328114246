import getForeignExchangeRate from '~/graphql/queries/data/get-foreign-exchange-rate.gql'

export default async({ app, store }: any) => {
  try {
    const client = app.apolloProvider.defaultClient
    const response = await client.query({ query: getForeignExchangeRate })
    const foreignExchangeRates = response.data.foreignExchangeRateCollection.items
    store.dispatch('list/setExchangeRates', foreignExchangeRates ?? [])
  } catch (error) {
    store.dispatch('list/setExchangeRates', [])
    // eslint-disable-next-line no-console
    console.error('Error occurred while fetching Foreign Exchange Rate Collection.', error)
  }
}