import Vue, { DirectiveBinding, VNode } from 'vue'
import { NRAction } from '~/constants/newrelic-action'
import { NewRelicService } from '~/services/new-relic-service'

const getNRService = (vue: Vue | undefined): NewRelicService => vue!.$newRelic

type NRElement = HTMLElement & {disabled?: boolean}

function callInterActionText(
  el: NRElement,
  binding: DirectiveBinding<NRAction>,
  vnode: VNode,
  eventListener: keyof Pick<NRElement, 'addEventListener' | 'removeEventListener'>
) {
  const newRelic = getNRService(vnode.context)
  const nrAction = binding.value
  if (newRelic?.loaded && nrAction) {
    const eventAction = binding?.arg ?? 'click'
    el[eventListener](eventAction, () => newRelic.interActionText(nrAction))
  }
}

export default function() {
  Vue.directive('nr-action', {
    bind(el: NRElement, binding: DirectiveBinding<NRAction>, vnode: VNode) {
      if (el?.disabled) { return }
      callInterActionText(el, binding, vnode, 'addEventListener')
    },
    update(el: NRElement, binding: DirectiveBinding<NRAction>, vnode: VNode) {
      if (el?.disabled) { return }
      callInterActionText(el, binding, vnode, 'addEventListener')
    },
    unbind(el: NRElement, binding: DirectiveBinding<NRAction>, vnode: VNode) {
      callInterActionText(el, binding, vnode, 'removeEventListener')
    }
  })
}