export default class Helpers {
  static cloneNestedArray(items: any[]): any[] {
    return JSON.parse(JSON.stringify([...items]))
  }

  static getCurrentISODateString(additionalDays: number = 0): string {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + additionalDays)
    return currentDate.toISOString().split('T')[0]
  }
}