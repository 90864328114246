import getTooltips from '~/graphql/queries/data/get-tooltips.gql'

export default async({ app, store }: any) => {
  const client = app.apolloProvider.defaultClient

  const tooltipsResponse = await client.query({
    query: getTooltips,
    variables: {
      locale: store.state.localisation.locale,
      preview: store.state.settings.preview
    }
  })
  const tooltips = tooltipsResponse.data.edsTooltipsCollection.items

  store.dispatch('list/setTooltips', tooltips)
}