import { gql } from '@apollo/client/core'

export default (
  where: object | null = null,
  order: Array<string> | null = null
) => {
  const whereClause = JSON.stringify({ ...where, ...{ publicationDateUnixString_exists: true } })

  // Code below removes the double quotes from the stringified keys. For example:
  // Before: { "featuredProduct": true, "publicationDateUnixString_exists": true }
  // After: { featuredProduct: true, publicationDateUnixString_exists: true }

  const keySelectorRegex = /"([^"]+)":/g
  const valueWithoutQuotesRegex = '$1:'
  const formattedWhereClause = whereClause.replace(keySelectorRegex, valueWithoutQuotesRegex)

  return gql`
    query(
      $preview: Boolean = false,
      $limit: Int = 1000,
      $skip: Int = 0,
      $withTotal: Boolean = false
      $locale: String = "en-US"
    ) {
      edsProductCollection(
        ${order ? `order: [${order.join(',')}]` : ''}
        limit: $limit,
        skip: $skip,
        preview: $preview,
        locale: $locale,
        ${where ? `where: ${formattedWhereClause}` : ''}
      ) {
        items {
          releaseStatus
          sapMaterialNumber
          isbn
          featuredProduct
          accessibleViaCambridgeOne
          independentLearning
          classBased
          limitedQuantity
          soldOut
          bibliographicTitle
          additionalSubtitle
          imageUrl
          largeImageUrl
          prices
          publicationDate
          publicationDateUnixString
          description
          shortDescription
          briefDescription
          includes
          authors
          contributors
          cefrLevels
          edition
          educationLevelName
          englishType
          family
          formatCode
          formatName
          publisherName
          subscriptionTermUnit
          subscriptionTermValue
          themaSubjectCodes
          themaQualifierCodes
          bicSubjectCodes
          productRanges
          dataFixed
          globalPublicationStatus
          sales
        },
        total @include(if: $withTotal)
      }
    }
  `
}