import Country from '~/interfaces/country'
import SalesOrgPrices from '~/interfaces/sales-org-prices'
import CountryCode from '~/interfaces/country-code'
import countryCodes from '~/plugins/country-codes'
import ExchangeRate from '~/interfaces/exchange-rate'

export default {
  methods: {
    /**
     * Formats the price.
     *
     * Typical usage example:
     * formatPrice(30.0000, 'GBP')
     * --- output: £30.00
     *
     * @param price The price value
     * @param currency The currency code
     */
    formatPrice(price: number, currency: string): string {
      const isoPrefixedCurrencies = [
        'AUD',
        'CAD',
        'NZD',
        'USD',
        'USD-N',
        'USD-E',
      ]
      const { iso, symbol } = countryCodes.find(
        (code: CountryCode) => code.currency === currency) || { iso: 'GB', symbol: '£' }
      const fixedLengthPrice = Number(price).toFixed(2)
      if (currency === 'EUR') {
        return `${symbol} ${fixedLengthPrice.replace('.', ',')}`
      } else if (currency === 'GBP') {
        return `${symbol}${fixedLengthPrice}`
      } else if (isoPrefixedCurrencies.includes(currency)) {
        return `${iso}${symbol}${fixedLengthPrice}`
      }
      return ''
    },
    /**
    * Reference EXP-1093
    * @param  {Array}   prices       Product Prices
    * @param  {Country} countryData  Country data in the state
    * @param  {Boolean} gtm          GTM format
    * @return {String}               Formatted price
    */
    formatPriceDisplay(prices: any, countryData: Country, exchangeRates?: ExchangeRate[], gtm = false) {
      const {
        currency,
        salesOrganisation
      } = countryData

      const countryCurrency = countryData?.countryCurrency ?? undefined

      let activeCurrency = salesOrganisation === '1288' && currency === 'USD' ? 'USD-E' : currency

      const dataPrice = prices[activeCurrency] > 0
        ? prices[activeCurrency]
        : prices.salesOrgPrices.find(
          (data: SalesOrgPrices) => data.currency === currency && data.salesOrg === salesOrganisation)?.price

      const exchangeRate = this.getExchangeRate(activeCurrency, exchangeRates, countryCurrency)

      let price = (dataPrice || -1) * (exchangeRate ?? 1)

      if (gtm && price && Number(price) > 0) {
        return `${price}`
      } else {
        if (Number(price) <= 0) {
          price = prices.GBP || prices.salesOrgPrices.find((data: SalesOrgPrices) => (data.currency === 'GBP'))?.price
          activeCurrency = 'GBP'
        }

        if (exchangeRate !== undefined) {
          const options = {
            style: 'currency',
            currency: countryCurrency
          }
          const fixedPrice = Number(Number(price).toFixed(2)).toLocaleString(undefined, options)
          return `<strong>${fixedPrice}</strong>`
        }

        let currencyIso = 'GB'
        let currencySymbol = '£'
        countryCodes.forEach((code: CountryCode) => {
          if (code.currency === activeCurrency) {
            currencyIso = code.iso
            currencySymbol = code.symbol
          }
        })
        let formattedPrice = ''

        switch (activeCurrency) {
        case 'EUR': {
          formattedPrice = `<strong>${currencySymbol} ${Number(price).toFixed(2).replace('.', ',')}</strong>`
          break
        }
        case 'GBP': {
          formattedPrice = `<strong>${currencySymbol}${Number(price).toFixed(2)}</strong>`
          break
        }
        case 'USD':
        case 'USD-N':
        case 'USD-E': {
          formattedPrice = `${currencyIso}<strong>${currencySymbol}${Number(price).toFixed(2)}</strong>`
          break
        }
        case 'CAD': {
          formattedPrice = `${currencyIso}<strong>${currencySymbol}${Number(price).toFixed(2)}</strong>`
          break
        }
        case 'AUD': {
          formattedPrice = `${currencyIso}<strong>${currencySymbol}${Number(price).toFixed(2)}</strong>`
          break
        }
        case 'NZD': {
          formattedPrice = `${currencyIso}<strong>${currencySymbol}${Number(price).toFixed(2)}</strong>`
          break
        }
        }

        return formattedPrice
      }
    },

    calculateUnitPrice(totalPriceIncTax: number, quantity: number) :number {
      const unitPrice: number = totalPriceIncTax / quantity
      return parseFloat(unitPrice.toFixed(2))
    },
    getExchangeRate(
      activeCurrency: string,
      exchangeRates?: ExchangeRate[],
      countryCurrency?: string
    ): number | undefined {
      if (exchangeRates?.length && countryCurrency) {
        const countryExchangeRates = exchangeRates?.find(
          rate => rate.base === activeCurrency
        )

        if (countryExchangeRates?.rates && countryCurrency in countryExchangeRates?.rates) {
          return countryExchangeRates.rates[countryCurrency]
        }
      }
      return undefined
    }
  }
}