import { Vue } from 'nuxt-property-decorator'
import { toWordsOrdinal } from 'number-to-words'

import ImpressionProduct from '~/interfaces/impression-product'
import Product from '~/interfaces/product'

export default Vue.extend({
  methods: {
    sortProducts(products: Array<Product>, key: string, currencyCode: string) {
      return products.sort((aData: any, bData: any) => {
        let aValue = aData[key]
        let bValue = bData[key]

        if (key === 'prices' && currencyCode) {
          const { salesOrganisation, currency } = this.$store.state.country
          const activeCurrency = salesOrganisation === '1288' && currency === 'USD' ? 'USD-E' : currency

          aValue = parseFloat(aValue[activeCurrency]) > 0
            ? parseFloat(aValue[activeCurrency])
            : (aValue.salesOrgPrices.find((data: any) => data.currency === activeCurrency)?.price || 0)
          bValue = parseFloat(bValue[activeCurrency]) > 0
            ? parseFloat(bValue[activeCurrency])
            : (bValue.salesOrgPrices.find((data: any) => data.currency === activeCurrency)?.price || 0)

          // Sort by title A-Z in case price values are same.
          if (aValue === bValue) {
            aValue = aValue + aData.bibliographicTitle
            bValue = bValue + bData.bibliographicTitle
          }

          return aValue < bValue ? -1 : 1
        }

        /* Retain order if conditions above are not met.
          Returning aData instead of 1 to fix the different behavior of sort function in Chrome and Firefox
          when returns 1
        */
        return aData
      })
    },
    togglePopupFilterDesktop() {
      // @ts-ignore
      this.isPopupFilterDesktopShown = !this.isPopupFilterDesktopShown
    },
    // TODO: give whereOptions an explicit type definition.
    setWhereClause(initialSearchOptions: any, whereOptions: any = {}) {
      const whereClause: any = {
        AND: []
      }

      if (whereOptions.AND) {
        whereClause.AND.push(...whereOptions.AND)
      }

      if (whereOptions.OR) {
        whereClause.AND.push({ OR: [...whereOptions.OR] })
      }

      whereClause.AND.push(initialSearchOptions)

      return whereClause
    },
    subscriptionTermFormat(product: Product, isForHeader: boolean = false, translation: string = ''): string {
      const { subscriptionTermUnit, subscriptionTermValue } = product
      let term = ''
      switch (subscriptionTermUnit.toLowerCase()) {
      case 'y':
        term = this.$t('year')
        break
      case 'm':
        term = this.$t('month')
        break
      case 'd':
        term = this.$t('day')
        break
      case 'h':
        term = this.$t('hour')
        break
      default:
        return ''
      }
      if (!String(subscriptionTermValue).trim()) {
        return ''
      }
      if (isForHeader) {
        if (translation === 'en-US') {
          return `${subscriptionTermValue} ${term}${parseInt(subscriptionTermValue) > 1 ? 's\'' : '\'s'}`
        }
        if (translation === 'de-DE') {
          return `${subscriptionTermValue} ${term}${parseInt(subscriptionTermValue) > 1 ? 'e' : ''}`
        }
        if (translation === 'pt') {
          return `${subscriptionTermValue} ${term}${parseInt(subscriptionTermValue) > 1 ? 'es' : ''}`
        }
      }
      // eslint-disable-next-line max-len
      return `${subscriptionTermValue} ${term}${parseInt(subscriptionTermValue) > 1 && translation === 'en-US' ? 's' : ''}`
    },
    productLabelFormat(product: Product, keyName: string = 'formatName'): string {
      let name = this.filterFormatName(product.formatName, product.formatCode)

      if (keyName === 'publisherName') {
        name = product.publisherName
      }

      // filter N/A
      const filterNA = (val: string) => {
        if (val) {
          return val.toLowerCase().trim() === 'n/a' ? '' : val
        }
        return ''
      }
      name = filterNA(name)

      return this.$t(name) || ''
    },
    filterFormatName(formatName: string, formatCode?: string): string {
      switch (formatName?.toLowerCase().trim()) {
      case 'digital mixed media':
      case 'mixed media product':
        return 'Digital Media'
      case 'static online product':
        return 'Online Content'
      case 'epub ebook':
      case 'adobe ebook reader':
        return 'Ebook'
      case 'online resource':
        return formatCode?.toLowerCase() === 'dh' ? 'Ebook' : formatName
      }
      return formatName
    },
    sortPublicationDate(products: any): Array<Product> {
      if (products) {
        return products.slice().sort((productA: Product, productB: Product) => {
          const productUnixA = parseFloat(productA.publicationDateUnixString)
          const productUnixB = parseFloat(productB.publicationDateUnixString)
          if (productUnixA === productUnixB) {
            return productA.bibliographicTitle < productB.bibliographicTitle ? -1 : 1
          }
          return productUnixA < productUnixB ? 1 : -1
        }).filter((product: Product, i: number) => i < 12 ? product : false)
      }
      return []
    },
    cefrLevelFormat(product: Product): string {
      const { cefrLevels } = product
      const cefrText = this.$t('ABC to XYZ')
      if (cefrLevels && !cefrLevels.includes('N/A')) {
        const from = cefrLevels[0]
        const to = cefrLevels[cefrLevels.length - 1]
        return from === to ? from : cefrText.replace('ABC', from).replace('XYZ', to)
      }
      return ''
    },
    formatImpressionProducts(products: Array<Product>, source: string): Array<ImpressionProduct> {
      return products?.map((product: Product, index: number) => ({
        product,
        source,
        position: index + 1
      } as ImpressionProduct))
    },
    formatTitle(title: string): string {
      if (typeof title !== 'undefined') {
        const formattedTitle: any = []
        const words = title.split(' ')

        if (words && words.length > 0) {
          words.forEach((word: string) => {
            word.length > 25 ? formattedTitle.push(word.slice(0, 21) + '.. ') : formattedTitle.push(word)
          })
        }
        return formattedTitle.join(' ').replace(/[‘’]/g, '\'')
      }
      return title
    },
    formatEdition(value: string): string {
      let ordinal = value.toString()
      if (Number(value) > 1) {
        ordinal = toWordsOrdinal(Number(value))
        // Word is then further formatted in case the converted value has a dash (e.g. thirty-fourth).
        ordinal = ordinal.split('-').map((word: string) => word[0].toUpperCase() +
          word.substring(1)).join('-') +
          ' Edition'
      }
      if (ordinal === '1' || ordinal === '1st Edition') {
        ordinal = 'First Edition'
      }
      return ordinal
    },
    /**
     * Formats the product publication date.
     * @returns A human readable date string if the value is not 'n/a'. Otherwise returns 'N/A` if
     * the passed value is N/A or not a valid date string.
     */
    formatPublicationDate(publicationDate: string) {
      const dateNumber = Number(publicationDate)
      const noValue = ''
      let dateMoment: any
      if (Number.isNaN(dateNumber)) {
        if (publicationDate?.trim().toLocaleUpperCase() === noValue) {
          return publicationDate?.trim().toLocaleUpperCase()
        }
        dateMoment = this.$moment(publicationDate)
      } else {
        dateMoment = this.$moment.unix(publicationDate)
      }
      // const formattedDate = dateMoment.format(dateFormat)
      const dateDay = dateMoment.format('DD')
      const dateMonth = this.$t(dateMoment.format('MMMM'))
        ? this.$t(dateMoment.format('MMMM'))
        : dateMoment.format('MMMM')
      const dateYear = dateMoment.format('YYYY')
      const formattedDate = `${dateDay} ${dateMonth} ${dateYear}`
      return formattedDate.includes('Invalid date') || formattedDate === '01 January 1970' ? noValue : formattedDate
    },
    /**
     * Parses the publication date from the product object.
     */
    parsePublicationDate({ publicationDate, publicationDateUnixString }: Product): string {
      if (publicationDateUnixString?.trim()) {
        return publicationDateUnixString
      } else if (publicationDate?.trim()) {
        return publicationDate
      } else {
        return ''
      }
    },
    /**
     * Parses the publisher from the product object.
     */
    parsePublisher({ publisherName }: Product): string | null {
      if (publisherName?.trim()) {
        return publisherName
      } else {
        return ''
      }
    },
  }
})