import {
  validateTempCustomerId, getSsoToken, updateBasketItems, navigateToBasket
} from './basket-common-service'
import { NewRelicService } from './new-relic-service'
import BasketProps from '~/interfaces/basket-props'
import { NRAction } from '~/constants/newrelic-action'

export default async function(
  axios: any, basketProps: BasketProps, $store: any, $cookies: any, newRelic: NewRelicService
) {
  try {
    const headers = {
      common: {
        'x-api-key': basketProps.apiKey
      }
    }

    const loginToken = $store.state.user?.token || null
    const loginUser = $store.state.user?.user || null

    if (loginToken && loginUser) {
      axios.setToken(loginToken, 'Bearer')
    }

    const storeId = basketProps.storeId
    const customerId: string = await validateTempCustomerId(
      axios, basketProps, headers, $store.state.customer.customerId
    )

    if (!$store.state.customer.customerId) {
      const products = $store.state.list.basket
      for (const sapMaterialNumber of products) {
        await updateBasketItems(
          axios, basketProps, headers, customerId, storeId, sapMaterialNumber
        )
      }
    }

    $store.dispatch('customer/setCustomerId', customerId)

    // @ts-ignore
    if (gigya) {
      // @ts-ignore
      await gigya.accounts.session.verify()
    }

    const ssoToken = await getSsoToken(axios, basketProps, headers, customerId, storeId)
    await navigateToBasket(basketProps.checkoutUrl, $store, $cookies, ssoToken)
    newRelic.interActionText(NRAction.GoToBasket)
  } catch (response: any) {
    // eslint-disable-next-line no-console
    console.log(response.message, 'There was an error in the POST checkout API call.')
  }
}