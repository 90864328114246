
  import { Vue } from 'nuxt-property-decorator'
  import getLocalisedContent from '~/graphql/queries/data/get-localised-content.gql'
  import getTooltips from '~/graphql/queries/data/get-tooltips.gql'
  import LocalisedContent from '~/interfaces/localisedContent'
  import Country from '~/interfaces/country'
  import Language from '~/interfaces/language'

  export default Vue.extend({
    data():{
      selectedCountryData: Country | null,
      selectedLanguageData: Language | null
    } {
      return {
        selectedCountryData: null,
        selectedLanguageData: null
      }
    },
    computed: {
      isCountrySelected(): boolean {
        return !this.selectedCountryData?.countryCodeIso2
      },
      isLanguageSelected(): boolean {
        return !this.selectedLanguageData?.locale
      }
    },
    methods: {
      closeMenu() {
        this.$emit('closeDropdown')
      },
      async applySettings() {
        this.updateCountryStore()
        await this.updateLanguageStore()
        this.closeMenu()
      },
      updateSelectedCountry(countryData: Country) {
        this.selectedCountryData = countryData
      },
      updateSelectedLanguage(languageData: Language) {
        this.selectedLanguageData = languageData
      },
      updateCountryStore(): void {
        this.$store.dispatch('country/setCountryName', this.selectedCountryData?.countryName)
        this.$store.dispatch('country/setCountryCode', this.selectedCountryData?.countryCodeIso2)
        this.$store.dispatch('country/setCountryCurrencyCode', this.selectedCountryData?.currency)
        this.$store.dispatch('country/setKonakartCurrencyCode', this.selectedCountryData?.currencyKonakart)
        this.$store.dispatch('country/setSalesOrganisation', this.selectedCountryData?.salesOrganisation)
        this.$store.dispatch(
          'country/setLocalCountryCurrency',
          this.selectedCountryData?.countryCurrency ?? undefined
        )
        this.$store.dispatch('setIsCountryChange', this.selectedCountryData?.countryName)
      },
      async updateLanguageStore() {
        this.$store.dispatch('localisation/setLocale', this.selectedLanguageData?.regionalLocale)
        await this.updateLocalisedContent()
      },
      async updateLocalisedContent() {
        const client = this.$apolloProvider.defaultClient

        const localisedContentResponse = await client.query({
          query: getLocalisedContent,
          variables: {
            locale: this.$store.state.localisation.locale,
            preview: this.$store.state.settings.preview
          }
        })
        const localisedContent: LocalisedContent[] =
          localisedContentResponse?.data?.edsLocalisedContentCollection?.items
        const content: any = {}
        for (const item of localisedContent) {
          content[item.enValue] = item.localisation
        }
        await this.$store.dispatch('localisation/setContent', content)

        const tooltipsResponse = await client.query({
          query: getTooltips,
          variables: { locale: this.$store.state.localisation.locale }
        })
        const tooltips = tooltipsResponse.data.edsTooltipsCollection.items
        await this.$store.dispatch('list/setTooltips', tooltips)

        await ['signin', 'register', 'reset_password'].forEach((page) => {
          if (window.location.pathname.includes(page)) {
            window.location.reload()
          }
        })
      }
    }
  })
