/* eslint-disable @typescript-eslint/no-unused-vars */
import { OrderHistoryOrder } from '~/interfaces/order-history'
import orderHistory from '~/services/mocks/orders-history'

export default async function(
  limit: number | undefined,
  $store: any
) {
  try {
    const loginUser = $store.state.user?.user || null
    if (loginUser) {
      const orderIds = orderHistory?.orders.map((orderData: OrderHistoryOrder) => {
        const orderId = (orderData.orders_id).toString()
        return orderId
      })
      await $store.dispatch('list/setOrderIds', orderIds)
      // returning empty orders as order history is implemented with mock data for now
      return {
        orders: [],
        totalOrders: 0
        // orders: !limit ? orderHistory?.orders : orderHistory?.orders.slice(0, limit),
        // totalOrders: orderHistory?.orders.length
      }
    }
  } catch (response: any) {
    // eslint-disable-next-line no-console
    console.log(response.message, 'There was an error in the GET Orders API call.')
  }
}