import getLanguages from '~/graphql/queries/data/get-languages.gql'

export default async({ app, store }: any) => {
  const client = app.apolloProvider.defaultClient

  const response = await client.query({
    query: getLanguages,
    variables: {
      preview: store.state.settings.preview,
      locale: store.state.localisation.locale
    }
  })
  const languages = response?.data?.edsLanguagesCollection?.items
  store.dispatch('list/setLanguages', languages)
}