
  import { Vue } from 'nuxt-property-decorator'
  import { mapState } from 'vuex'
  import mixinSetGigyaBehaviors from '~/mixins/setGigyaBehaviors'
  import mixinProductQuantityMessage from '~/mixins/productQuantityMessage'
  import mixinGetNpsPopup from '~/mixins/getNpsPopupSetting'

  export default Vue.extend({
    mixins: [
      mixinSetGigyaBehaviors,
      mixinProductQuantityMessage,
      mixinGetNpsPopup
    ],

    data() {
      return {
        isFixed: false,
        menuOpen: false,
        windowY: 0,
        showMoreOpen: false,
        lang: this.getLocale()
      }
    },
    computed: {
      ...mapState({
        isCountryChange: 'isCountryChange',
        isPopupOpen: 'isPopupOpen'
      })
    },
    watch: {
      // watch the isCountryChange, if country changes, update the sorting based on updated currency.
      isCountryChange(newCountry) {
        const vuePage: any = this.$refs?.page
        // find the current page instance which have sorting feature
        const page = vuePage?.$children?.find((currentPage: any) => currentPage.products && currentPage.sortOptions)
        // if page exist and selected sorting is based on prices, execute the sort function
        if (page?.sortOptions.sortKey === 'prices' && page.products?.length > 0 && newCountry) {
          page.products = page.sortProducts(
            page.products, page.sortOptions.sortKey, page.sortOptions.sortOrder, page.sortOptions.currencyCode)
        }
      },
      // 'isPopupOpen' variable enable/disable body scroll based on its value.
      // If 'isPopupOpen' becomes true, disable body scroll; if false, enable body scroll.
      isPopupOpen(newValue) {
        this.handleBodyScroll(newValue)
      }
    },
    beforeCreate() {
      if (this.$store.state.showPopupAddedToBasket === true) {
        this.$store.dispatch('setShowPopupAddedToBasket', false)
      }
      this.$store.dispatch('setShowAddToBasketErrorPopup', false)
      this.$store.dispatch('setIsRetryAddingToBasket', false)
      this.$store.dispatch('setShowBasketStickyBtn', false)
      this.$store.dispatch('setIsPopupOpen', false)
    },
    mounted() {
      this.scrollHeader()
      if (!this.$store.state.user?.user?.UID) {
        this.$basketSync()
      }
      this.addCountriesFieldOnModalRender()
      if (this.isNewSessionStarted()) {
        this.saveUserAgentCount()
      }
      if (this.shouldNpsPopupDisplay()) {
        this.displayNpsPopup()
      }
    },
    methods: {
      isHidden() {
        const refHeader = this.$refs.header as any
        const scrollHeight = refHeader ? refHeader.$el.scrollHeight : 0
        return this.windowY > scrollHeight && !this.isFixed
      },
      setMenuOpen(value: boolean) {
        this.menuOpen = value
      },
      setShowMore(value: boolean) {
        this.showMoreOpen = value
      },
      scrollHeader() {
        let oldValue = 0
        window.addEventListener('scroll', () => {
          const refHeader = this.$refs.header as any
          const scrollHeight = refHeader ? refHeader.$el.scrollHeight : 0
          this.windowY = window.scrollY
          if ((oldValue < this.windowY) || (scrollHeight === 0 && this.windowY === 0)) {
            this.isFixed = false
          } else if (scrollHeight < this.windowY) {
            this.isFixed = true
          }
          oldValue = this.windowY
        })
      },
      // adds or removes the class from the body to prevent or allow scrolling.
      handleBodyScroll(disableScroll = false) {
        document.body.classList.remove('overflow-hide')
        if (disableScroll) {
          document.body.classList.add('overflow-hide')
        }
      }
    }
  })
