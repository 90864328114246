// @ts-ignore
import Context from '~/interfaces/context.ts'

export default ({ $config }: Context) => {
  if (window.location.protocol === 'http:' && $config.envLocal !== 'localhost') {
    window.location.replace(window.location.href.replace('http:', 'https:'))
  }
  if (window.location.pathname.includes('test-and-train')) {
    window.location.replace(window.location.href.replace('test-and-train', 'test-%26-train'))
  }
  if (window.location.pathname.includes('search')) {
    const returnUrl = window.location.href.split('?')
    if (returnUrl.length === 1) {
      const searchParam = sessionStorage.getItem('searchParam')
      if (searchParam !== null) {
        window.location.replace(`${window.location.href}?${searchParam}`)
      }
    }
    sessionStorage.removeItem('searchParam')
  }
}