/* eslint-disable no-console */
import { NRAction } from '~/constants/newrelic-action'

type NRServiceProps = {
  debug?: boolean
}

export class NewRelicService {
  private newrelic: any = undefined
  private debug: boolean = false

  private get interaction(): Record<string, Function> {
    const [interaction] = this.isLoaded(() => this.newrelic.interaction())
    return interaction
  }

  public loaded: boolean = false

  constructor(props: NRServiceProps) {
    try {
      this.debug = !!props?.debug
      this.init()
    } catch (err) {
      console.error(`Newrelic error: ${err}`)
    }
  }

  private log(msg: string): void {
    if (!this.debug) { return }
    console.debug(`[NR]: ${msg}`)
  }

  private init(): void {
    if (window?.newrelic) {
      this.newrelic = window?.newrelic
      this.loaded = true
      window.addEventListener('beforeunload', () => this.end())
      this.log(`loaded: ${this.newrelic}`)
    }
  }

  private isLoaded(fn: Function): [any, unknown] {
    try {
      if (this.loaded) {
        const result = fn()
        return [result, null]
      }
      return [null, null]
    } catch (error) {
      return [null, error]
    }
  }

  public interActionText(action: NRAction): unknown {
    const [response, error] = this.isLoaded(
      () => {
        this.log(`actionText ${action}`)
        this.interaction.actionText(action).save()
        this.interaction.end()
      }
    )

    if (error) {
      console.error(`Newrelic interaction actionText: ${error}`)
    }

    return response ?? error
  }

  public end(): unknown {
    const [response, error] = this.isLoaded(
      () => {
        this.log('end')
        this.interaction.end()
      }
    )

    if (error) {
      console.error(`Newrelic interaction end: ${error}`)
    }

    return response ?? error
  }
}