export default (_context: any, inject: any) => {
  inject('crypter', {
    encodeURL(string: string) {
      try {
        return string ? encodeURI(window.btoa(string)) : ''
      } catch {
        // eslint-disable-next-line no-console
        console.log('There was a problem in string to encode')
        return ''
      }
    },
    decodeURL(string: string) {
      try {
        return string ? window.atob(decodeURI(string)) : ''
      } catch {
        // eslint-disable-next-line no-console
        console.log('There was a problem in string to decode')
        return ''
      }
    }
  })
}