import BasketProps from '~/interfaces/basket-props'

export async function fetchTempCustomerId(
  axios: any,
  basketProps: BasketProps,
  headers: any
): Promise<string> {
  let tempCustomerId
  const tempCustomerApi = async() => {
    const response = await axios({
      baseUrl: basketProps.apiUrl,
      url: `${basketProps.apiUrl}/temp-customer`,
      method: 'post',
      headers
    })
    return response.data.id
  }

  try {
    while (!tempCustomerId) {
      const id: string = await tempCustomerApi()
      if (!String(id).includes('/')) {
        tempCustomerId = id
        break
      }
    }

    return tempCustomerId
  } catch (response: any) {
    throw new Error(`There was an error in the POST temporary customer id API call: ${response.message}`)
  }
}

export async function validateTempCustomerId(
  axios: any,
  basketProps: BasketProps,
  headers: any,
  customerId: any
): Promise<string> {
  let validCustomerId = customerId
  if (!validCustomerId ||
    (typeof customerId === 'object' && Object.keys(customerId).length === 0) ||
    typeof validCustomerId !== 'string') {
    validCustomerId = await fetchTempCustomerId(axios, basketProps, headers)
  }
  return validCustomerId
}

export async function getSsoToken(
  axios: any,
  basketProps: BasketProps,
  headers: any,
  customerId: string | number,
  storeId: string
) {
  try {
    return (await axios({
      baseUrl: basketProps.apiUrl,
      url: `${basketProps.apiUrl}/ssotoken`,
      method: 'post',
      headers,
      data: {
        customerId,
        storeId
      }
    })).data.id
  } catch (response: any) {
    throw new Error(`There was an error in the GET SSO token API call: ${response.message}`)
  }
}

export async function getBasketItems(
  axios: any,
  basketProps: BasketProps,
  headers: any,
  customerId: string | number,
  storeId: string
) {
  try {
    return (await axios({
      baseUrl: basketProps.apiUrl,
      url: `${basketProps.apiUrl}/basket/${customerId}/${storeId}`,
      method: 'get',
      headers
    })).data
  } catch (response: any) {
    throw new Error(`There was an error in the GET basket items API call: ${response.message}`)
  }
}

export async function updateBasketItems(
  axios: any,
  basketProps: BasketProps,
  headers: any,
  customerId: string | number,
  storeId: string,
  sapMaterialNumber: string
): Promise<void> {
  try {
    await axios({
      baseUrl: basketProps.apiUrl,
      url: `${basketProps.apiUrl}/basket/items`,
      method: 'post',
      headers,
      data: {
        customerId,
        storeId,
        product: {
          sku: sapMaterialNumber,
          quantity: 1
        }
      }
    })
  } catch (response: any) {
    throw new Error(`There was an error in the POST basket items API call: ${response.message}`)
  }
}

export function getGoogleAnalyticsIds($cookies: any): { [key: string]: string } {
  const cookies = $cookies.getAll() as { [key: string]: string }
  const cookeys: string[] = Object.keys(cookies)
  let clientId = ''
  let sessionId = ''

  cookeys.forEach((key: string) => {
    if (key === '_ga') {
      clientId = cookies[key].split('.').slice(2).join('.')
    }
    if (key.includes('_ga_')) {
      // eslint-disable-next-line prefer-destructuring
      sessionId = cookies[key].split('.')[2]
    }
  })
  return {
    clientId,
    sessionId
  }
}

export async function navigateToBasket(
  url: string,
  $store:any,
  $cookies: any,
  ssoToken: string,
  options: any = {}
): Promise<void> {
  const { currency, salesOrganisation } = $store.state.country
  let checkoutCurrency = currency
  if (currency === 'USD') {
    checkoutCurrency = salesOrganisation === '1288' ? 'USD-E' : 'USD-N'
  }
  const { clientId, sessionId } = getGoogleAnalyticsIds($cookies)

  // Using sessionStorage to store search param instead of cookies / Vuex(local Storage) beacuse
  // when go to basket page opens in same tab and we need search params only in same tab.
  if (window.location.pathname.includes('search')) {
    sessionStorage.setItem('searchParam', window.location.href.split('?')[1])
  }
  const returnUrl = window.location.href.split('?')[0].replace(/%26/g, 'and')
  const lang = $store.state.localisation.locale ? $store.state.localisation.locale.substring(0, 2) : 'en'
  // eslint-disable-next-line max-len
  let params = `&lang=${lang}&cur=${checkoutCurrency}&returnurl=${returnUrl}` +
    `&_ga=${clientId}&clientId=${clientId}&sessionId=${sessionId}`
  if (options?.productId) {
    params = `${params}&productId=${options.productId}`
  }
  await window.location.assign(`${url}?token=${ssoToken}${params}`)
}