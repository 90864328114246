import getSettings from '~/graphql/queries/data/get-settings.gql'
import { EDSCookie } from '~/constants/cookie'

export default async({ app, store }: any) => {
  const edsCookieMaintenanceBanner: boolean | undefined = app.$cookies.get(EDSCookie.MaintenanceBanner)
  const removeCookie = () => {
    if (edsCookieMaintenanceBanner === undefined) { return }
    app.$cookies.remove(EDSCookie.MaintenanceBanner, { path: '/', maxAge: 0 })
  }
  try {
    const client = app.apolloProvider.defaultClient
    const response = await client.query({
      query: getSettings,
      variables: { settingName: 'EDS Maintenance Banner Setting' }
    })
    store.dispatch('settings/setMaintenanceSettings', {
      enabled: edsCookieMaintenanceBanner ?? response.data.edsSettingsCollection.items[0].toggleSetting,
      messaging: response.data.edsSettingsCollection.items[0].messagingText
    })

    if (!response.data.edsSettingsCollection.items[0].toggleSetting) {
      removeCookie()
    }
  } catch {
    store.dispatch('settings/setMaintenanceSettings', {
      enabled: false,
      messaging: ''
    })

    removeCookie()
  }
}