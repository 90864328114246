export const state = () => ({
  user: null,
  displayRegisterSuccessMsg: false,
  autoPopulateRegisterEmail: ''
});

export const mutations = {
  SET_USER_DATA(state, value) {
    state.user = value;
  },
  SET_USER_TOKEN(state, value) {
    state.token = value;
  },
  SET_SESSION_ID(state, value) {
    state.sessionId = value;
  },
  SET_DISPLAY_REGISTER_SUCCESS_MSG(state, value) {
    state.displayRegisterSuccessMsg = value;
  },
  SET_AUTO_POPULATE_REGISTER_EMAIL(state, value) {
    state.autoPopulateRegisterEmail = value;
  }
};

export const actions = {
  setUserData({ commit }, value) {
    commit('SET_USER_DATA', value);
  },
  setUserToken({ commit }, value) {
    commit('SET_USER_TOKEN', value);
  },
  setSessionId({ commit }, value) {
    commit('SET_SESSION_ID', value);
  },
  setDisplayRegisterSuccessMsg({ commit }, value) {
    commit('SET_DISPLAY_REGISTER_SUCCESS_MSG', value);
  },
  setAutoPopulateRegisterEmail({ commit }, value) {
    commit('SET_AUTO_POPULATE_REGISTER_EMAIL', value);
  }
};