import { render, staticRenderFns } from "./PopupMenuLocationMobile.vue?vue&type=template&id=21a46702&scoped=true&"
import script from "./PopupMenuLocationMobile.vue?vue&type=script&lang=ts&"
export * from "./PopupMenuLocationMobile.vue?vue&type=script&lang=ts&"
import style0 from "./PopupMenuLocationMobile.vue?vue&type=style&index=0&id=21a46702&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a46702",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopupMenuLocationBase: require('/home/runner/work/english-digital-shop-frontend/english-digital-shop-frontend/components/Popup/PopupMenuLocationBase.vue').default})
