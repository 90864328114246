
  import { Vue } from 'nuxt-property-decorator'

  export default Vue.extend({
    props: {
      userFirstName: {
        type: String,
        required: true
      }
    },
    computed: {
      userInitials(): string {
        // regex to remove leading and trailing white spaces
        const sanitizedFirstName = this.userFirstName.replace(/(^\s+)|(\s+$)/g, '')
        return sanitizedFirstName.charAt(0).toUpperCase()
      }
    }
  })
