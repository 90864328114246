
  import { Vue } from 'nuxt-property-decorator'
  import FooterLink from '~/interfaces/footer-links'
  import mixinHandleLinkClick from '~/mixins/handleLinkClick'

  export default Vue.extend({
    mixins: [mixinHandleLinkClick],
    computed: {
      copyrightYear(): number {
        return new Date().getFullYear()
      },
      footerLinks(): FooterLink[] {
        return this.getFooterLinks()
      }
    },
    methods: {
      getFooterLinks(): FooterLink[] {
        return [...this.$store.state.list.footerLinks]
      },
      getURIQueryParam(params: any) {
        let query = ''
        for (const key of Object.keys(params)) {
          query += (query ? '&' : '?') + encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }
        return query
      }
    }
  })
