import { Vue } from 'nuxt-property-decorator'

export default Vue.extend({
  methods: {
    $_handleLinkClick(event: any): void {
      event.preventDefault()

      const adCampaignParams: string[] = []
      const query = this.$route.query
      const params: any = {}

      let url = event.currentTarget.href
      let queryString = ''

      this.$store.state.list.adCampaignParams.forEach((adCampaign: any) => {
        adCampaignParams.push(...adCampaign.params)
      })

      Object.keys(query).forEach((key) => {
        // If given key exists in the list of ad campaign params.
        if (adCampaignParams.includes(key)) {
          params[key] = query[key]
        }
      })

      Object.keys(params).forEach((key) => {
        queryString += `${key}=${params[key]}&`
      })

      if (Object.keys(query).length === 0) {
        window.open(url, '_blank', 'noopener')
      } else {
        url += url.includes('?') ? '&' : '?'
        queryString = queryString.slice(0, -1)

        window.open(url + queryString, '_blank', 'noopener')
      }
    }
  }
})