export const state = () => ({
  locale: null,
  content: {},
  defaultLocale: 'en-US'
});

export const mutations = {
  SET_LOCALE(state, locale) {
    state.locale = locale;
  },
  SET_CONTENT(state, content) {
    state.content = content;
  },
  SET_CONTENT_ITEM(state, contentItem) {
    state.content = {
      ...state.content,
      [contentItem]: contentItem
    };
  }
};

export const actions = {
  setLocale({ commit }, locale) {
    commit('SET_LOCALE', locale);
  },
  setContent({ commit }, content) {
    commit('SET_CONTENT', content);
  },
  setContentItem({ commit }, contentItem) {
    commit('SET_CONTENT_ITEM', contentItem);
  }
};

export const getters = {
  translate: state => (key, env) => {
    const logCondition = [
      key,
      env === 'dev',
      state?.locale !== 'en-US',
      state?.locale !== undefined
    ].every(bool => bool);

    if (key && key in state.content) {
      const translation = state.content[key];
      if (logCondition && translation === key) {
        // eslint-disable-next-line no-console
        console.debug(`Same translation \n [en-US]: ${key} \n [${state?.locale}]: ${translation}`);
      }
      return translation;
    }

    if (logCondition) {
      // eslint-disable-next-line no-console
      console.warn(`No ${state?.locale} translation:\n${key}`);
    }

    return key;
  },
};