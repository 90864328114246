export default {
  methods: {
    getProductImageUrl(productImageUrl: string): string {
      const hasPlaceholderLink = productImageUrl?.includes('placeholder')
      const isNull = productImageUrl === null
      const isEmpty = productImageUrl === ''
      const isUndefined = productImageUrl === undefined
      const hasNoImageUrl = hasPlaceholderLink || isNull || isEmpty || isUndefined

      return (hasNoImageUrl) ? require('~/assets/images/product-image-placeholder.png') : productImageUrl
    }
  }
}